import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { resetUserPassword } from '../../services/authService'; // Função que envia a nova senha

export default function ResetPassword() {
	const [searchParams] = useSearchParams(); // Captura o token da URL
	const token = searchParams.get('token'); // Obtém o token

	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (newPassword !== confirmPassword) {
			setError('As senhas não coincidem.');
			setMessage('');
			return;
		}

		try {
			await resetUserPassword(token, newPassword);
			setMessage('Senha redefinida com sucesso! Você pode fazer login agora.');
			setError('');
		} catch (err) {
			setError('Erro ao redefinir senha. O link pode ter expirado.');
			setMessage('');
		}
	};

	return (
		<Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
			<Typography component="h1" variant="h5">
				Redefinir Senha
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					label="Nova Senha"
					type="password"
					autoComplete="new-password"
					value={newPassword}
					onChange={e => setNewPassword(e.target.value)}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					label="Confirmar Nova Senha"
					type="password"
					autoComplete="new-password"
					value={confirmPassword}
					onChange={e => setConfirmPassword(e.target.value)}
				/>
				<Button type="submit" fullWidth variant="contained" color="primary">
					Redefinir Senha
				</Button>
			</form>

			{message && (
				<Alert severity="success" style={{ marginTop: '10px' }}>
					{message}
				</Alert>
			)}
			{error && (
				<Alert severity="error" style={{ marginTop: '10px' }}>
					{error}
				</Alert>
			)}
		</Container>
	);
}
