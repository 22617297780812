import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiUpdatePlatform,
	IPlatformBackEnd,
	IClientBackEnd,
	apiDeletePlatform,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário

interface UpdatePlatformProps {
	platforms: IPlatformBackEnd[];
	clients: IClientBackEnd[];
	updatedDataToggle: boolean;
}

function UpdatePlatform({
	platforms,
	clients,
	updatedDataToggle,
}: UpdatePlatformProps) {
	const [updatedPlatforms, setUpdatedPlatforms] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	useEffect(() => {
		// Ordena as plataformas uma vez quando o componente é montado
		const sortedPlatforms = [...platforms].sort((a, b) =>
			a.platform_name.localeCompare(b.platform_name)
		);
		setUpdatedPlatforms(sortedPlatforms);
	}, [platforms, updatedDataToggle]);

	const handleUpdatePlatform = async (platform: IPlatformBackEnd) => {
		if (!platform.client) {
			toast.error('Cliente não pode ser nulo');
			return;
		}

		const updatedPlatform = {
			platformName: platform.platform_name,
			platformCode: platform.platform_code,
			clientId: platform.client.id,
		};

		try {
			await apiUpdatePlatform(platform.id, updatedPlatform);
			toast.success('Plataforma atualizada com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar a plataforma');
		}
	};

	const handleDelete = async (platformId: number) => {
		try {
			await apiDeletePlatform(platformId);
			const updatedPlatformsNew = updatedPlatforms.filter(
				platform => platform.id !== platformId
			);
			setUpdatedPlatforms(updatedPlatformsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: a plataforma possui um cliente ou um projeto ligado a ela. Altere-os antes.'
			);
		}
	};

	const handleNameChange = (id: number, newName: string) => {
		setUpdatedPlatforms(prevPlatforms =>
			prevPlatforms.map(platform =>
				platform.id === id ? { ...platform, platform_name: newName } : platform
			)
		);
	};

	const handleClientChange = (id: number, newClientId: number) => {
		const newClient = clients.find(client => client.id === newClientId);
		if (!newClient) return;

		setUpdatedPlatforms(prevPlatforms =>
			prevPlatforms.map(platform =>
				platform.id === id ? { ...platform, client: newClient } : platform
			)
		);
	};

	const handlePlatformCodeChange = (id: number, newPlatformCode: string) => {
		setUpdatedPlatforms(prevPlatforms =>
			prevPlatforms.map(platform =>
				platform.id === id
					? { ...platform, platform_code: newPlatformCode }
					: platform
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Plataformas
				</Typography>
				{updatedPlatforms.map(platform => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={platform.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label="Nome Plataforma"
								value={platform.platform_name}
								onChange={e => handleNameChange(platform.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="Código Plataforma"
								value={platform.platform_code}
								onChange={e =>
									handlePlatformCodeChange(platform.id, e.target.value)
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id={`client-select-label-${platform.id}`}>
									Cliente
								</InputLabel>
								<Select
									labelId={`client-select-label-${platform.id}`}
									value={platform.client ? String(platform.client.id) : ''}
									onChange={e =>
										handleClientChange(platform.id, Number(e.target.value))
									}
									label="Cliente"
								>
									{clients.map(client => (
										<MenuItem key={client.id} value={client.id}>
											{client.client_name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdatePlatform(platform)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setSelectedId(platform.id);
									setIsDeleteDialogOpen(true);
								}}
								sx={{
									backgroundColor: 'red',
									'&:hover': { backgroundColor: 'darkred' },
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdatePlatform;
