import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Filters from '../../components/OperationalProjection/Filters/Filters';
import {
	IOperationalProjection,
	apiGetOperationalProjection,
} from '../../services/apiService';
import SummaryBar from '../../components/SummaryBar';
import ProjectionTable from '../../components/OperationalProjection/ProjectionTable/ProjectionTable';
import { getMonthBoundaryDate } from '../../utils/getMonthBoundaryDate';
import axios from 'axios';
import { toast } from 'react-toastify';
import MonthlyTable from '../../components/OperationalProjection/MonthlyTable/MonthlyTable';
import { Fullscreen, FullscreenExit, Download } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import {
	formatTableDataForExport,
	formatSummaryDataForExport,
	formatMonthlyDataForExport,
} from './utils';

function OperationalProjectionPage() {
	const [operationalProjection, setOperationalProjection] = useState<
		IOperationalProjection | undefined
	>(undefined);
	const [
		operationalProjectionBySituation,
		setOperationalProjectionBySituation,
	] = useState<IOperationalProjection | undefined>(undefined);
	const [startDate, setStartDate] = useState<string>(
		getMonthBoundaryDate('first')
	);
	const [endDate, setEndDate] = useState<string>(getMonthBoundaryDate('last'));
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchOperationalProjection = async () => {
			setIsLoading(true);
			try {
				const operationalProjection = await apiGetOperationalProjection(
					startDate,
					endDate,
					'',
					'',
					'',
					'',
					[],
					'',
          []
				);
				setOperationalProjection(operationalProjection);
				setOperationalProjectionBySituation(operationalProjection);
			} catch (error) {
				setOperationalProjection(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						setOperationalProjection(undefined);
						setOperationalProjectionBySituation(undefined);
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			}
			setIsLoading(false);
		};

		fetchOperationalProjection();
	}, []);

	// Função para baixar os dados em Excel
	const handleDownloadExcel = () => {
		const tableData = formatTableDataForExport(
			operationalProjectionBySituation
		);
		const summaryData = formatSummaryDataForExport(operationalProjection);
		const monthlyData = formatMonthlyDataForExport(operationalProjection);

		if (
			tableData.length > 0 ||
			summaryData.length > 0 ||
			monthlyData.length > 0
		) {
			const workbook = XLSX.utils.book_new();

			// Adiciona a aba principal com os dados da tabela
			if (tableData.length > 0) {
				const worksheet = XLSX.utils.aoa_to_sheet(tableData);
				XLSX.utils.book_append_sheet(workbook, worksheet, 'Projection Data');
			}

			// Adiciona uma nova aba com os dados do resumo
			if (summaryData.length > 0) {
				const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
				XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');
			}

			// Adiciona uma nova aba com os dados de cada mês
			if (monthlyData.length > 0) {
				const monthlySheet = XLSX.utils.aoa_to_sheet(monthlyData);
				XLSX.utils.book_append_sheet(workbook, monthlySheet, 'Monthly Data');
			}

			// Escreve o arquivo Excel
			XLSX.writeFile(workbook, 'operational_projection.xlsx');
		} else {
			toast.error('ERROR: Nenhum dado disponível para download.');
		}
	};

	return (
		<Grid style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '40px' }}>
				Gestão de planejamento - projeção operacional
			</Typography>
			<Filters
				operationalProjection={operationalProjection}
				setOperationalProjection={setOperationalProjection}
				setOperationalProjectionBySituation={
					setOperationalProjectionBySituation
				}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>
			<Box display="flex" flexDirection="row">
				<Box display="flex" width="100%" maxWidth="800px" marginTop={2}>
					<Paper
						elevation={3}
						style={{
							// paddingLeft: 15,
							paddingRight: 0,
							paddingBottom: 0.5,
							width: '100%',
						}}
					>
						<SummaryBar
							dataSummary={operationalProjection?.data_summary}
							startDate={startDate}
							endDate={endDate}
						/>
					</Paper>
				</Box>
				<Box marginTop={2} marginLeft={2}>
					<Paper elevation={1}>
						<MonthlyTable operationalProjection={operationalProjection} />
					</Paper>
				</Box>
			</Box>
			<Box
				style={{
					marginTop: isFullScreen ? 33 : 0,
					...(isFullScreen
						? {
								position: 'fixed',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 9999,
								backgroundColor: 'white',
								padding: 20,
						  }
						: {}),
				}}
			>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					style={{
						flexDirection: 'row',
						gap: '8px',
						position: isFullScreen ? 'fixed' : 'static',
						top: isFullScreen ? '10px' : 'auto',
						right: isFullScreen ? '10px' : 'auto',
						zIndex: 10000,
						marginBottom: isFullScreen ? '30px' : '8px', // Aumenta a margem inferior no modo expandido
					}}
				>
					<IconButton
						onClick={handleDownloadExcel}
						style={{
							backgroundColor: '#007eb8',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '4px',
						}}
					>
						<Download />
					</IconButton>
					<IconButton
						onClick={() => setIsFullScreen(!isFullScreen)}
						style={{
							backgroundColor: '#007eb8',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '4px',
						}}
					>
						{isFullScreen ? <FullscreenExit /> : <Fullscreen />}
					</IconButton>
				</Box>

				<ProjectionTable
					operationalProjection={operationalProjectionBySituation}
					startDate={startDate}
					endDate={endDate}
					isFullScreen={isFullScreen}
				/>
			</Box>
		</Grid>
	);
}

export default OperationalProjectionPage;
