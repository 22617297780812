import React from 'react';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { Column, Cell, Table2 } from '@blueprintjs/table';
import { IWorkerHistogram } from '../../../services/apiService';

interface IWorkerHistogramTableProps {
	workerHistogram: IWorkerHistogram | undefined;
	startDate: string;
	endDate: string;
	isFullScreen: boolean;
}

/**
 * Retorna um objeto com o dia da semana e o dia/mês, a partir de uma data no formato "YYYY-MM-DD".
 */
function getFormattedDate(date: string): {
	dayOfWeek: string;
	dayMonth: string;
} {
	const [year, month, day] = date.split('-');
	const d = new Date(`${year}-${month}-${day}T00:00:00`);
	const daysOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];
	return { dayOfWeek: daysOfWeek[d.getDay()], dayMonth: `${day}/${month}` };
}

function generateColumnWidth(n: number): number[] {
	const array: number[] = [200, 250];
	for (let i = 0; i < n; i++) {
		array.push(50);
	}
	return array;
}

function getLightColor(code: string): string {
	if (code.endsWith('*') || code.endsWith('*-D')) {
		return '#001D55'; // Preto
	}
	const colorMap: { [key: string]: string } = {
		STB: '#E0FFFF',
		TMF: '#F0E68C',
		E: '#FFD700',
		DE: '#FFA07A',
		FO: '#E6E6FA',
		AFI: '#D8BFD8',
		FE: '#FFB6C1',
		TMS: '#ADD8E6',
		H: '#98FB98',
		Q: '#FFDEAD',
		D: '#FFC0CB',
		DT: '#FFFACD',
		HO5: '#FAFAD2',
		HO1: '#F5DEB3',
		AD5: '#FFDAB9',
		TO: '#E0FFFF',
		FT: '#F0E68C',
		AD1: '#FFA07A',
		HI: '#E6E6FA',
		EI: '#D8BFD8',
		DI: '#FFB6C1',
		DEI: '#ADD8E6',
		FTO: '#98FB98',
		AT: '#FFE4E1',
		FTM: '#AFEEEE',
		ASO: '#90EE90',
		ITO: '#E6EEF5',
	};
	if (code === '') {
		return '#FFFFFF'; // Branco
	}
	return colorMap[code] || '#f5cc18';
}

function WorkerHistogramTable({
	workerHistogram,
	startDate,
	endDate,
	isFullScreen,
}: IWorkerHistogramTableProps) {
	if (!workerHistogram) return null;
	const { workers, days } = workerHistogram;

	// Estilo comum para cada célula do cabeçalho
	const headerCellStyle: React.CSSProperties = {
		borderBottom: '1px solid rgba(0,0,0,0.1)',
		borderRight: '1px solid rgba(0,0,0,0.1)',
		padding: 2,
		textAlign: 'center',
	};

	/**
	 * Renderiza o cabeçalho composto para cada coluna.
	 * Para as colunas 0 e 1 (não-data) são renderizadas 3 linhas:
	 *   - Linha 1: (fundo branco) para extra informação (para a coluna 1: "stb_counts"; para a 0: em branco)
	 *   - Linha 2: (fundo branco) para extra informação (para a coluna 1: "stb_perc"; para a 0: em branco)
	 *   - Linha 3: o rótulo da coluna (por exemplo, "Nome" ou "Função de operação")
	 *
	 * Para as colunas de data (índices ≥ 2) são renderizadas 4 linhas:
	 *   - Linha 1: valor de stb_counts (para esse dia)
	 *   - Linha 2: valor de stb_perc (para esse dia)
	 *   - Linha 3: o dia da semana (extraído da data)
	 *   - Linha 4: o dia/mês (extraído da data)
	 */
	const renderCompositeHeader = (
		colIndex: number,
		headerText: string
	): JSX.Element => {
		if (colIndex < 2) {
			// Colunas 0 e 1
			const extraHeader1 = colIndex === 0 ? '\u00A0' : 'Quantidade STB';
			const extraHeader2 = colIndex === 0 ? '\u00A0' : 'Porcentagem % STB';
			return (
				<div style={headerCellStyle}>
					<div
						style={{
							backgroundColor: 'white',
							borderBottom:
								colIndex === 0
									? '1px solid rgba(0,0,0,0)'
									: '1px solid rgba(0,0,0,0.1)',
							padding: 2,
						}}
					>
						{extraHeader1}
					</div>
					<div
						style={{
							backgroundColor: 'white',
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							padding: 2,
						}}
					>
						{extraHeader2}
					</div>
					<div
						style={{
							// backgroundColor: 'white',
							borderBottom: '1px solid rgba(0,0,0,0)',
							padding: 2,
							fontWeight: ['Nome', 'Função de operação'].includes(headerText)
								? 'bold'
								: 'normal',
						}}
					>
						{headerText}
					</div>
					<div
						style={{
							// backgroundColor: 'white',
							borderBottom: '1px solid rgba(0,0,0,0)',
							padding: 2,
						}}
					>
						{'\u00A0'}
					</div>
				</div>
			);
		} else {
			// Colunas de data (índices ≥ 2)
			// Aqui, headerText é a data no formato "YYYY-MM-DD"
			const { dayOfWeek, dayMonth } = getFormattedDate(headerText);
			// Índice para acessar os arrays de stb_counts e stb_perc:
			const dayIndex = colIndex - 2;
			return (
				<div style={headerCellStyle}>
					<div
						style={{
							backgroundColor: 'white',
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							padding: 2,
						}}
					>
						{workerHistogram.stb_counts[dayIndex]}
					</div>
					<div
						style={{
							backgroundColor: 'white',
							borderBottom: '1px solid rgba(0,0,0,0.1)',
							padding: 2,
						}}
					>
						{workerHistogram.stb_perc[dayIndex]}
					</div>
					<div
						style={{
							borderBottom: '1px solid rgba(0,0,0,0)',
							padding: 2,
							fontWeight: 'bold',
						}}
					>
						{dayOfWeek}
					</div>
					<div style={{ borderBottom: '1px solid rgba(0,0,0,0)', padding: 2 }}>
						{dayMonth}
					</div>
				</div>
			);
		}
	};

	const columns = [
		<Column
			key="worker-name"
			name="Nome"
			cellRenderer={(rowIndex: number) => (
				<Cell>{workers[rowIndex].worker_name}</Cell>
			)}
			columnHeaderCellRenderer={() => renderCompositeHeader(0, 'Nome')}
		/>,
		<Column
			key="operation-role"
			name="Função de operação"
			cellRenderer={(rowIndex: number) => (
				<Cell>{workers[rowIndex].operation_role}</Cell>
			)}
			columnHeaderCellRenderer={() =>
				renderCompositeHeader(1, 'Função de operação')
			}
		/>,
		...days.map((day, index) => (
			<Column
				key={`Day_${index}`}
				// Como o composite header para datas usará o valor da data original para calcular
				// o dia da semana e o dia/mês, passamos o próprio day (ex: "2025-02-01")
				name=""
				cellRenderer={(rowIndex: number) => {
					const eventCode = workers[rowIndex].events[index];
					const backgroundColor = getLightColor(eventCode);
					const textColor =
						eventCode.endsWith('*') || eventCode.endsWith('*-D')
							? '#FFFFFF'
							: '#000000';
					// Remove todas as ocorrências de '*'
					const cleanedEventCode = eventCode.replace(/\*/g, '');
					// Separa em até duas linhas (firstPart e secondPart) usando \n
					let [firstPart, secondPart] = cleanedEventCode.split('\n');
					// if (firstPart === secondPart) {
					// 	secondPart = '';
					// }
					return (
						<Cell
							style={{
								backgroundColor,
								color: textColor,
								lineHeight: 1.2,
								padding: '4px',
								textAlign: 'center', // Centraliza horizontalmente
							}}
						>
							{firstPart.replace('-D', '*')}
							{secondPart && (
								<>
									<br />
									{secondPart}
								</>
							)}
						</Cell>
					);
				}}
				columnHeaderCellRenderer={() => renderCompositeHeader(index + 2, day)}
			/>
		)),
	];

	return (
		<div
			style={{
				width: isFullScreen ? '100%' : 1545,
				height: isFullScreen ? 'calc(100vh - 100px)' : 600,
			}}
		>
			<Table2
				numRows={workers.length}
				numFrozenColumns={2}
				numFrozenRows={0}
				enableRowHeader={false}
				columnWidths={generateColumnWidth(workerHistogram.days.length)}
				defaultRowHeight={38}
			>
				{columns}
			</Table2>
		</div>
	);
}

export default WorkerHistogramTable;
