import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Grid } from '@mui/material';
import {
	IWorkerBoardingBackEnd,
	apiAddBoardingChangeDescription,
	apiDeleteWorkerBoarding,
	apiUpdateWorkerBoarding,
} from '../../services/apiService';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IEditWorkerBoardingForm } from '../../services/apiService';
import ConfirmWorkerDeleteDialog from './ConfirmWorkerDeleteDialog';
import ObservationPopup from '../../components/ObservationPopup';

const validationSchema = yup.object({
	workerName: yup
		.string()
		.max(255, 'O nome do operation_role deve ter no máximo 255 caracteres'),
	operationRole: yup
		.string()
		.max(255, 'O perfil do funcionário deve ter no máximo 255 caracteres'),
	scheduled_start_date: yup.date().nullable(),
	scheduled_end_date: yup.date().nullable(),
	actual_start_date: yup.date().nullable(),
	actual_end_date: yup.date().nullable(),
});

interface Props {
	workerBoarding: IWorkerBoardingBackEnd;
	onRemove: (workerBoardingId: number) => void;
	applyReloadWorkerBoardingData: () => void;
	/** Controla se o popup de observação deve ser exibido */
	enableObservation: boolean;
}

const WorkerFormEditPlanning: React.FC<Props> = ({
	workerBoarding,
	onRemove,
	applyReloadWorkerBoardingData,
	enableObservation,
}) => {
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
	const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			workerName: workerBoarding.worker?.worker_name || 'A Definir',
			operationRole:
				workerBoarding.worker?.operation_role.description || 'A Definir',
			scheduled_start_date: workerBoarding.scheduled_start_date || null,
			scheduled_end_date: workerBoarding.scheduled_end_date || null,
		},
		validationSchema: validationSchema,
		// Esta função será chamada após a confirmação no pop-up ou quando não há observação
		onSubmit: async values => {
			const { workerName, operationRole, ...dates } = values;
			const editData: IEditWorkerBoardingForm = {
				worker_id: workerBoarding.worker_id,
				boarding_id: workerBoarding.boarding_id,
				...dates,
			};

			try {
				await apiUpdateWorkerBoarding(workerBoarding.id, editData);
				toast.success('SUCCESS: funcionário atualizado');
			} catch (error) {
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError;
					if (
						axiosError.response &&
						axiosError.response.data !== null &&
						typeof axiosError.response.data === 'object' &&
						'detail' in axiosError.response.data
					) {
						const errorMessage = axiosError.response.data.detail;
						console.log('Detalhe do erro:', errorMessage);
						toast.error('ERROR: ' + (errorMessage as string));
					} else {
						console.log('Erro desconhecido:', axiosError);
						toast.error('ERROR: erro desconhecido (*1)');
					}
				} else {
					console.log('Erro desconhecido:', error);
					toast.error('ERROR: erro desconhecido (*2)');
				}
			}
		},
	});

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	// Função chamada ao clicar no botão "Deletar"
	const handleDeleteButtonClick = () => {
		setIsDeleteDialogOpen(true);
	};

	// Após confirmação do diálogo de exclusão, decide se abre o popup de observação ou deleta diretamente
	const handleDeleteAfterConfirm = async () => {
		if (enableObservation) {
			setIsDeletePopupOpen(true);
		} else {
			try {
				await apiDeleteWorkerBoarding(workerBoarding.id);
				toast.success('Registro deletado com sucesso!');
				onRemove(workerBoarding.id);
				applyReloadWorkerBoardingData();
			} catch (error) {
				toast.error('Erro ao deletar o registro.');
			}
		}
	};

	// Função chamada ao salvar a observação no popup de exclusão
	const handleDeleteConfirm = async (
		actionType: string,
		observation: string
	) => {
		const username = localStorage.getItem('username') ?? '';
		const addBoardingChangeDescription = {
			boarding_id: workerBoarding.boarding_id,
			action_type: actionType,
			observation: observation,
			worker_name: workerBoarding.worker?.worker_name,
			scheduled_embarkation_date_before: workerBoarding.scheduled_start_date,
			scheduled_disembarkation_date_before: workerBoarding.scheduled_end_date,
			scheduled_embarkation_date_after: formik.values.scheduled_start_date,
			scheduled_disembarkation_date_after: formik.values.scheduled_end_date,
			changed_number_boarding: null,
			user: username,
		};

		await apiAddBoardingChangeDescription(addBoardingChangeDescription);

		await apiDeleteWorkerBoarding(workerBoarding.id);
		toast.success('Registro deletado com sucesso!');
		onRemove(workerBoarding.id);
		setIsDeletePopupOpen(false);
		applyReloadWorkerBoardingData();
	};

	// Função para tratar o clique no botão "Atualizar"
	const handleUpdateButtonClick = async () => {
		if (enableObservation) {
			setIsUpdatePopupOpen(true);
		} else {
			await formik.submitForm();
			applyReloadWorkerBoardingData();
		}
	};

	// Função chamada ao salvar a observação no popup de atualização
	const handleSaveUpdatePopup = async (
		actionType: string,
		observation: string
	) => {
		const username = localStorage.getItem('username') ?? '';
		const addBoardingChangeDescription = {
			boarding_id: workerBoarding.boarding_id,
			action_type: actionType, // campo enviado para o backend
			observation: observation,
			worker_name: workerBoarding.worker?.worker_name,
			scheduled_embarkation_date_before: workerBoarding.scheduled_start_date,
			scheduled_disembarkation_date_before: workerBoarding.scheduled_end_date,
			scheduled_embarkation_date_after: formik.values.scheduled_start_date,
			scheduled_disembarkation_date_after: formik.values.scheduled_end_date,
			changed_number_boarding: null,
			user: username,
		};
		await apiAddBoardingChangeDescription(addBoardingChangeDescription);
		setIsUpdatePopupOpen(false);

		await formik.submitForm();
		applyReloadWorkerBoardingData();
	};

	return (
		<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
			<Grid container spacing={3} direction="row">
				<Grid item>
					<TextField
						id="workerName"
						name="workerName"
						label="Nome"
						value={formik.values.workerName}
						onChange={formik.handleChange}
						error={
							formik.touched.workerName && Boolean(formik.errors.workerName)
						}
						helperText={formik.touched.workerName && formik.errors.workerName}
						disabled
					/>
				</Grid>
				<Grid item>
					<TextField
						id="operationRole"
						name="operationRole"
						label="Função (perfil)"
						value={formik.values.operationRole}
						onChange={formik.handleChange}
						error={
							formik.touched.operationRole &&
							Boolean(formik.errors.operationRole)
						}
						helperText={
							formik.touched.operationRole && formik.errors.operationRole
						}
						disabled
					/>
				</Grid>
				<Grid item>
					<TextField
						id="scheduled_start_date"
						name="scheduled_start_date"
						label="Embarque (PROG)"
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
						value={formik.values.scheduled_start_date}
						onChange={formik.handleChange}
						error={
							formik.touched.scheduled_start_date &&
							Boolean(formik.errors.scheduled_start_date)
						}
						helperText={
							formik.touched.scheduled_start_date &&
							formik.errors.scheduled_start_date
						}
						style={{ width: '100%', maxWidth: '235px' }}
					/>
				</Grid>
				<Grid item>
					<TextField
						id="scheduled_end_date"
						name="scheduled_end_date"
						label="Desembarque (PROG)"
						type="date"
						InputLabelProps={{
							shrink: true,
						}}
						value={formik.values.scheduled_end_date}
						onChange={formik.handleChange}
						error={
							formik.touched.scheduled_end_date &&
							Boolean(formik.errors.scheduled_end_date)
						}
						helperText={
							formik.touched.scheduled_end_date &&
							formik.errors.scheduled_end_date
						}
						style={{ width: '100%', maxWidth: '235px' }}
					/>
				</Grid>
				<Grid item>
					<Button
						color="primary"
						variant="contained"
						onClick={handleUpdateButtonClick}
						sx={{ mr: 2 }}
					>
						Atualizar
					</Button>
					<Button
						variant="contained"
						onClick={handleDeleteButtonClick}
						sx={{
							backgroundColor: 'red',
							'&:hover': { backgroundColor: 'darkred' },
							color: 'white',
						}}
					>
						Deletar
					</Button>
				</Grid>
			</Grid>
			<ConfirmWorkerDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					handleDeleteAfterConfirm();
					setIsDeleteDialogOpen(false);
				}}
				onCancel={() => setIsDeleteDialogOpen(false)}
			/>
			<ObservationPopup
				open={isDeletePopupOpen}
				onClose={() => setIsDeletePopupOpen(false)}
				actionType="Exclusão do funcionário."
				onSave={handleDeleteConfirm}
			/>
			<ObservationPopup
				open={isUpdatePopupOpen}
				onClose={() => setIsUpdatePopupOpen(false)}
				actionType="Atualização da programação do funcionário."
				onSave={handleSaveUpdatePopup}
			/>
		</form>
	);
};

export default WorkerFormEditPlanning;
