import axios from 'axios';
import qs from 'qs';
import { create } from './httpService';

const BASE_URL =
	process.env.REACT_APP_NODE_ENV === 'development'
		? 'http://localhost:8000'
		: '/api';

export interface LoginCredentials {
	username: string;
	password: string;
}

export interface ForgotPasswordRequest {
	email: string;
}

export const loginUser = async (credentials: LoginCredentials) => {
	const data = qs.stringify({
		username: credentials.username,
		password: credentials.password,
	});

	const config = {
		method: 'post',
		url: `${BASE_URL}/user/login`, // Garanta que a URL esteja correta e inclua http:// ou https://
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Accept: 'application/json',
		},
		data,
	};

	try {
		const response = await axios.request(config);
		// localStorage.setItem('token', response.data.access_token);
		// localStorage.setItem('token_expires_at', response.data.expires_at);

		// Supondo que você queira retornar o token e a data de expiração diretamente:
		return {
			token: response.data.access_token,
			expiresAt: response.data.expires_at,
			visibilityRoles:
				response.data.user_module_visibility.module_role.visibility_roles,
		};
	} catch (error) {
		// Aqui você pode querer tratar o erro ou simplesmente repassá-lo:
		throw error;
	}
};

export const requestPasswordReset = async (email: string) => {
	try {
		const response = await create('/user/forgot-password', { email });
		return response;
	} catch (error) {
		throw error;
	}
};

export const resetUserPassword = async (
	token: string | null,
	newPassword: string
) => {
	if (!token) throw new Error('Token inválido ou ausente.');

	try {
		// Enviando os dados no corpo da requisição como JSON
		const response = await create('/user/reset-password', {
			token,
			new_password: newPassword,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
