// src/App.tsx
import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useNavigate,
} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
// import  from '../pages/Login';
import Login from '../pages/Login/Login';
import ProjectRegistrationPage from '../pages/ProjectRegistrationPage/ProjectRegistrationPage';
import ProjectPlanningPage from '../pages/ProjectPlanningPage/ProjectPlanningPage';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from '../features/auth/authSlice';
import { validToken } from '../utils/validToken';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditPlanning from '../pages/ProjectPlanningPage/EditPlanningPage';
import PeoplePage from '../pages/PeoplePage/PeoplePage';
import OperationalProjectionPage from '../pages/OperationalProjectionPage/OperationalProjectionPage';
import WorkerHistogramPage from '../pages/WorkerHistogramPage/WorkerHistogramPage';
import PerformanceOverview from '../pages/PerformanceOverview/PerformanceOverview';
import WorkerSkillsPage from '../pages/WorkerSkillsPage/WorkerSkillsPage';
import BoardingAssignmentPage from '../pages/BoardingAssignmentPage/BoardingAssignmentPage';
import ProjectEditPage from '../pages/ProjectEditPage/ProjectEditPage';
import BoardingAdd from '../components/BoardingAdd';
import SettingsModulePage from '../pages/SettingsModulePage/SettingsModulePage';
import { existInVisibilityRoleInLocalStorage } from '../utils/existInVisibilityRoleInLocalStorage';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';

const RedirectToProperPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// Verifica se "hide project registration" está no localStorage
		if (!existInVisibilityRoleInLocalStorage('hide project registration')) {
			navigate('/project-registration');
		} else {
			navigate('/project-edit');
		}
	}, [navigate]);

	return null; // Não renderiza nada, apenas redireciona
};

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		if (validToken()) {
			dispatch(setLoggedIn(true));
		} else {
			dispatch(setLoggedIn(false));
		}
	}, [dispatch]);

	return (
		<>
			<ToastContainer />
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route element={<PrivateRoute />}>
						<Route
							path="/project-registration"
							element={<ProjectRegistrationPage />}
						/>
						<Route path="/project-planning" element={<ProjectPlanningPage />} />
						<Route path="/edit-planning/:id" element={<EditPlanning />} />
						<Route path="/people" element={<PeoplePage />} />
						<Route
							path="/operational-projection"
							element={<OperationalProjectionPage />}
						/>
						<Route path="/worker-histogram" element={<WorkerHistogramPage />} />
						<Route
							path="/performance-overview"
							element={<PerformanceOverview />}
						/>
						<Route path="/worker-skills" element={<WorkerSkillsPage />} />
						<Route
							path="/boarding-assignment"
							element={<BoardingAssignmentPage />}
						/>
						<Route path="/project-edit" element={<ProjectEditPage />} />
						<Route path="/boarding-add" element={<BoardingAdd />} />
						<Route path="/settings-module" element={<SettingsModulePage />} />
					</Route>
					{/* Outras rotas públicas aqui */}
					<Route
						path="*"
						element={<RedirectToProperPage />} // Substitui o <Navigate> com a lógica condicional
					/>
				</Routes>
			</Router>
		</>
	);
}

export default App;
