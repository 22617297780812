import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { requestPasswordReset } from '../../services/authService'; // Importando o serviço
import { ClipLoader, MoonLoader } from 'react-spinners';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false); // Estado do loader

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true); // Ativa o loader

		try {
			const response = await requestPasswordReset(email);
			setMessage(response.message);
			setError('');
		} catch (err) {
			setError(
				'Erro ao enviar e-mail de recuperação. Verifique o e-mail digitado.'
			);
			setMessage('');
		} finally {
			setLoading(false); // Desativa o loader quando a resposta chega
		}
	};

	return (
		<Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
			<Typography component="h1" variant="h5">
				Recuperar Senha
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="E-mail"
					name="email"
					autoComplete="email"
					autoFocus
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={loading}
				>
					Enviar Link de Recuperação
				</Button>
			</form>
			{loading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '15px',
					}}
				>
					<MoonLoader size={30} />
				</div>
			)}

			{message && (
				<Alert severity="success" style={{ marginTop: '10px' }}>
					{message}
				</Alert>
			)}
			{error && (
				<Alert severity="error" style={{ marginTop: '10px' }}>
					{error}
				</Alert>
			)}
		</Container>
	);
}
