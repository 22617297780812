import { toast } from 'react-toastify';
import {
	apiCreateImage,
	apiCreateProject,
	apiDeleteAllImages,
	apiDeleteImage,
	apiDeleteProject,
	apiDeleteProjectByProjectNumber,
	apiGetFullProjectByProjectNumber,
	apiGetLastProjectNumber,
	apiUpdateProject,
	IImageAction,
	IImageBackend,
	IImageOutputBackend,
	IImageOutputS3Backend,
	IProjectFormBackend,
} from './apiService';
import axios, { AxiosError } from 'axios';

function findImageId(
	imageBackend: IImageBackend,
	projectNumber: number,
	imageOutputList: IImageOutputBackend[]
): number | null {
	const targetImageUrl = `${projectNumber}/${imageBackend.name}`;

	const matchingImage = imageOutputList.find(
		imageOutput => imageOutput.image_url === targetImageUrl
	);

	return matchingImage ? matchingImage.id : null; // Retorna o ID ou null se não encontrar
}

function removeAddRemovePairs(actions: IImageAction[]): IImageAction[] {
	const result: IImageAction[] = [...actions]; // Faz uma cópia do array para evitar mutação

	// Remove pares "add" seguidos por "remove" com o mesmo nome
	for (let i = 0; i < result.length; i++) {
		const action = result[i];

		if (action.action === 'add') {
			for (let j = i + 1; j < result.length; j++) {
				if (
					result[j].action === 'remove' &&
					result[j].image.name === action.image.name
				) {
					result.splice(j, 1); // Remove o "remove"
					result.splice(i, 1); // Remove o "add"
					i--; // Ajusta o índice para continuar corretamente
					break;
				}
			}
		}
	}

	// Remove pares "remove" seguidos por "add" com o mesmo nome
	for (let i = 0; i < result.length; i++) {
		const action = result[i];

		if (action.action === 'remove') {
			for (let j = i + 1; j < result.length; j++) {
				if (
					result[j].action === 'add' &&
					result[j].image.name === action.image.name
				) {
					result.splice(j, 1); // Remove o "add"
					result.splice(i, 1); // Remove o "remove"
					i--; // Ajusta o índice para continuar corretamente
					break;
				}
			}
		}
	}

	return result;
}

interface IUpdateProjectBackendProps {
	selectedProjecId: number;
	transformedValues: IProjectFormBackend;
	setIsSubmitting: (b: boolean) => void;
	imagesAction: IImageAction[];
	setImagesAction: (s: IImageAction[]) => void;
	imagesIdFullProject: IImageOutputBackend[];
	setImagesIdFullProject: (b: IImageOutputBackend[]) => void;
}

export const updateProjectBackend = async ({
	selectedProjecId,
	transformedValues,
	setIsSubmitting,
	imagesAction,
	setImagesAction,
	imagesIdFullProject,
	setImagesIdFullProject,
}: IUpdateProjectBackendProps): Promise<void> => {
	try {
    const username = localStorage.getItem('username') ?? '';
		await apiUpdateProject(selectedProjecId, transformedValues, username);
		setImagesAction(removeAddRemovePairs(imagesAction));

		// Filtra os pares "add" e "remove"
		const filteredImagesAction = removeAddRemovePairs(imagesAction);
		setImagesAction(filteredImagesAction);

		try {
			for (const imageAction of filteredImagesAction) {
				if (imageAction.action === 'add') {
					await apiCreateImage(
						transformedValues.projectNumber,
						imageAction.image
					);
				} else if (imageAction.action === 'remove') {
					const id_delete = findImageId(
						imageAction.image,
						Number(transformedValues.projectNumber),
						imagesIdFullProject
					);

					if (id_delete !== null) {
						// Executa a função somente se o id_delete for válido
						await apiDeleteImage(Number(id_delete));
					} else {
						console.error('ID não encontrado. Ação cancelada.');
					}
				}

				const fullProject = await apiGetFullProjectByProjectNumber(
					Number(transformedValues.projectNumber)
				);
				setImagesIdFullProject(fullProject.images);
			}
		} catch (error) {
			console.log('🚀  error:', error);
			toast.error('ERROR: falha ao salvar imagens.');
			throw new Error(
				'Falha ao salvar imagens e limpeza realizada. Detalhes: ' +
					(error as Error).message
			);
		}

		setImagesAction([]);
		toast.success('SUCCESS: projeto atualizado');

		// #
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			if (
				axiosError.response &&
				axiosError.response.data !== null &&
				typeof axiosError.response.data === 'object' &&
				'detail' in axiosError.response.data
			) {
				const errorMessage = axiosError.response.data.detail;
				// console.log('Detalhe do erro:', errorMessage);
				toast.error('ERROR: ' + (errorMessage as string));
			} else {
				// console.log('Erro desconhecido:', axiosError);
				toast.error('ERROR: erro desconhecido (*1)');
			}
		} else {
			// console.log('Erro desconhecido:', error);
			toast.error('ERROR: erro desconhecido (*2)');
		}
	} finally {
		setIsSubmitting(false); // Desativa o spinner
	}
};
