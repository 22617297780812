// BoardingChangeDescriptionEditPlanningTable.tsx
import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material';
import { IBoardingChangeDescriptionBackend } from '../../services/apiService';
import { formatDateDMY, formatDateTimeCreatedAt } from '../../utils/formatDateTimeCreatAt';

interface Props {
	boardingChangeDescription: IBoardingChangeDescriptionBackend[];
}

const BoardingChangeDescriptionEditPlanningTable: React.FC<Props> = ({
	boardingChangeDescription,
}) => {
	return (
		<TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
			<Table
				sx={{ minWidth: 650 }}
				aria-label="boarding change description table"
			>
				<TableHead>
					<TableRow>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Usuário
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Data
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Ação
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Observação
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Funcionário
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Programação Anterior
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Programação Atual
						</TableCell>
						<TableCell
							align="center"
							sx={{
								backgroundColor: '#d3d3d3', // Cabeçalho em cinza mais escuro
								fontWeight: 'bold',
							}}
						>
							Nº Embarque Alterado
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{boardingChangeDescription.map((item, index) => (
						<TableRow
							key={item.id}
							sx={{
								backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#f1f1f1', // Linhas alternadas em cinza bem claro
							}}
						>
							<TableCell align="center">{item.user || 'N/A'}</TableCell>
							<TableCell align="center">
								{item.create_at ? formatDateTimeCreatedAt(item.create_at) : ''}
							</TableCell>
							<TableCell align="center">{item.action_type}</TableCell>
							<TableCell align="center">{item.observation}</TableCell>
							<TableCell align="center">{item.worker_name || '-'}</TableCell>
							<TableCell align="center">
								{`${formatDateDMY(
									item.scheduled_embarkation_date_before
								)} - ${formatDateDMY(
									item.scheduled_disembarkation_date_before
								)}`}
							</TableCell>
							<TableCell align="center">
								{`${formatDateDMY(
									item.scheduled_embarkation_date_after
								)} - ${formatDateDMY(
									item.scheduled_disembarkation_date_after
								)}`}
							</TableCell>
							<TableCell align="center">
								{item.changed_number_boarding || '-'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default BoardingChangeDescriptionEditPlanningTable;
