import React, { useState } from 'react';
import {
	Radio,
	RadioGroup,
	FormControl,
	FormControlLabel,
} from '@mui/material';

interface BooleanToggleProps {
	labelTrue: string;
	labelFalse: string;
	onChange?: (value: boolean) => void;
	defaultValue?: boolean;
}

const BooleanToggle: React.FC<BooleanToggleProps> = ({
	labelTrue,
	labelFalse,
	onChange,
	defaultValue = false,
}) => {
	const [value, setValue] = useState<boolean>(defaultValue);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value === 'true';
		setValue(newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<FormControl component="fieldset" sx={{ alignSelf: 'center' }}>
			<RadioGroup row value={value.toString()} onChange={handleChange}>
				<FormControlLabel value="true" control={<Radio />} label={labelTrue} />
				<FormControlLabel
					value="false"
					control={<Radio />}
					label={labelFalse}
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default BooleanToggle;
