import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	TextField,
	Button,
	Grid,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	FormControlLabel,
	Typography,
	Checkbox,
	Tooltip,
	IconButton,
	Paper,
} from '@mui/material';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import {
	IBoardingBackend,
	IBoardingStatusBackEnd,
	apiAddBoardingChangeDescription,
	apiUpdateBoardingNextBoardings,
	apiGetAllBoardingStatus,
} from '../../services/apiService';
import axios, { AxiosError } from 'axios';
import ObservationPopup from '../../components/ObservationPopup';
import { InfoOutlined } from '@mui/icons-material';

const styleCheckBox = {
	display: 'flex',
	alignItems: 'center',
	margin: 0,
	padding: 0,
	cursor: 'pointer',
	marginBottom: 10,
};

const validationSchema = yup.object({
	projectNumber: yup
		.number()
		.required('O número do projeto é obrigatório')
		.integer()
		.positive(),
	boardingNumber: yup
		.number()
		.required('O número do embarque é obrigatório')
		.integer()
		.positive(),
	clientName: yup
		.string()
		.required('O nome do cliente é obrigatório')
		.max(255, 'O nome do cliente deve ter no máximo 255 caracteres'),
	platformName: yup
		.string()
		.required('O nome do plataforma é obrigatório')
		.max(255, 'O nome do plataforma deve ter no máximo 255 caracteres'),
	projectScopeSow: yup
		.string()
		.required('O escopo é obrigatório')
		.max(255, 'O escopo deve ter no máximo 255 caracteres'),
	numberOfWorkers: yup
		.number()
		.required('O número de pessoas é obrigatório')
		.integer()
		.positive(),
	boardingStatus: yup.string().required('O status do embarque é obrigatória'),
	planned_embarkation_date: yup
		.date()
		.required('A data de embarcação esperada é obrigatória'),
	planned_disembarkation_date: yup
		.date()
		.required('A data de embarcação esperada é obrigatória'),
	scheduled_embarkation_date: yup.date().nullable(),
	scheduled_disembarkation_date: yup.date().nullable(),
	actual_embarkation_date: yup.date().nullable(),
	actual_disembarkation_date: yup.date().nullable(),
	observation: yup
		.string()
		.max(1000, 'Este campo pode ter no máximo 1000 caracteres'),
});

interface Props {
	boarding: IBoardingBackend;
	applyReloadWorkerBoardingData: () => void;
}

const BoardingFormEditPlanning: React.FC<Props> = ({
	boarding,
	applyReloadWorkerBoardingData,
}) => {
	const [dropdownBoardingStatus, setDropdownBoardingStatus] = useState<
		IBoardingStatusBackEnd[]
	>([]);
	const [considerDisembarkDay, setConsiderDisembarkDay] = useState<
		boolean | null
	>(boarding.consider_disembark_day ?? false);
	const [showInfoUpdate, setShowInfoUpdate] = useState(false);
	const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
	const [checkScheduleDateWorker, setCheckScheduleDateWorker] = useState(true);
	const [checkNextBoardings, setCheckNextBoardings] = useState(false);
	const [checkNBnumberOfWorkers, setCheckNBnumberOfWorkers] = useState(false);
	const [checkNBboardingStatus, setCheckNBboardingStatus] = useState(false);
	const [checkNBconsiderDisembarkDay, setCheckNBconsiderDisembarkDay] =
		useState(false);
	const [checkNBscheduledEmbarkationDate, setCheckNBscheduledEmbarkationDate] =
		useState(true);
	const [checkNBscheduledWorkerDate, setCheckNBscheduledWorkerDate] =
		useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [controlScheduledEmbarkationDate, setControlScheduledEmbarkationDate] =
		useState<string | null>(boarding.scheduled_embarkation_date);
	const [
		controlScheduledDisembarkationDate,
		setControlScheduledDisembarkationDate,
	] = useState<string | null>(boarding.scheduled_disembarkation_date);

	const disabledStyle = {
		'& .MuiInputBase-root.Mui-disabled': {
			// Estilos para o TextField desabilitado
			color: 'black', // Cor do texto
			'-webkit-text-fill-color': 'black', // Necessário para Chrome
			'& fieldset': {
				borderColor: 'grey', // Cor da borda
			},
		},
	};

	const formik = useFormik({
		initialValues: {
			projectNumber: boarding.project.project_number,
			boardingNumber: boarding.boarding_number,
			clientName: boarding.project.client.client_name,
			platformName: boarding.project.platform.platform_name,
			projectScopeSow:
				boarding.project?.project_scope_ids
					?.map(item => item?.project_scope?.sow ?? '')
					.filter(sow => sow !== '') // Filtra strings vazias para evitar elementos vazios na concatenação
					.join('  ,  ') ?? '',
			numberOfWorkers: boarding.number_of_workers,
			boardingStatus: boarding.boarding_status.id,
			planned_embarkation_date: boarding.planned_embarkation_date || null,
			planned_disembarkation_date: boarding.planned_disembarkation_date || null,
			scheduled_embarkation_date: boarding.scheduled_embarkation_date || null,
			scheduled_disembarkation_date:
				boarding.scheduled_disembarkation_date || null,
			actual_embarkation_date: boarding.actual_embarkation_date || null,
			actual_disembarkation_date: boarding.actual_disembarkation_date || null,
			observation: boarding.observation,
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			setIsLoading(true);

			const notEqualScheduledEmbarkationDate =
				controlScheduledEmbarkationDate !== values.scheduled_embarkation_date;
			const notEqualScheduledDisembarkationDate =
				controlScheduledDisembarkationDate !==
				values.scheduled_disembarkation_date;

			if (
				notEqualScheduledEmbarkationDate ||
				notEqualScheduledDisembarkationDate
			) {
				// Se as datas mudaram, abrir o popup para adicionar observação
				setIsUpdatePopupOpen(true);
				setIsLoading(false);
				return;
			}

			// Caso contrário, atualize os dados diretamente
			const user = localStorage.getItem('username') ?? '';
			await updateBoardingData(values, '', user);
		},
	});

	const updateBoardingData = async (
		values: any,
		observationBoardingChangeDescription: string,
		userBoardingChangeDescription: string
	) => {
		const {
			clientName,
			platformName,
			projectNumber,
			projectScopeSow,
			...editData
		} = values;

		try {
			await apiUpdateBoardingNextBoardings(boarding.id, {
				boardingUpdate: {
					...editData,
					considerDisembarkDay: considerDisembarkDay,
				},
				updateScheduleDateWorker: checkScheduleDateWorker,
				updateNextBoardings: checkNextBoardings,
				updateNBnumberOfWorkers: checkNBnumberOfWorkers,
				updateNBoardingStatus: checkNBboardingStatus,
				updateNBconsiderDisembarkDay: checkNBconsiderDisembarkDay,
				updateNBscheduledEmbarkationDate: checkNBscheduledEmbarkationDate,
				updateNBscheduledWorkerDate: checkNBscheduledWorkerDate,
				observationBoardingChangeDescription:
					observationBoardingChangeDescription,
				userBoardingChangeDescription: userBoardingChangeDescription,
			});
			applyReloadWorkerBoardingData();
			toast.success('SUCCESS: embarque atualizado');
		} catch (error) {
			handleApiError(error);
		}
		setIsLoading(false);
	};

	const handleApiError = (error: unknown) => {
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			const responseData = axiosError.response?.data;
			if (
				responseData &&
				typeof responseData === 'object' &&
				'detail' in responseData
			) {
				const errorMessage = (responseData as { detail: string }).detail;
				toast.error('ERROR: ' + errorMessage);
			} else {
				toast.error('ERROR: erro desconhecido (*1)');
			}
		} else {
			toast.error('ERROR: erro desconhecido (*2)');
		}
	};

	const handleSaveUpdatePopup = async (
		actionType: string,
		observation: string
	) => {
		const user = localStorage.getItem('username') ?? '';
		const addBoardingChangeDescription = {
			boarding_id: boarding.id,
			action_type: actionType,
			observation: observation,
			worker_name: null,
			scheduled_embarkation_date_before: controlScheduledEmbarkationDate,
			scheduled_disembarkation_date_before: controlScheduledDisembarkationDate,
			scheduled_embarkation_date_after:
				formik.values.scheduled_embarkation_date,
			scheduled_disembarkation_date_after:
				formik.values.scheduled_disembarkation_date,
			changed_number_boarding: null,
			user: user,
		};

		try {
			await updateBoardingData(formik.values, observation, user);
			await apiAddBoardingChangeDescription(addBoardingChangeDescription);
			setControlScheduledEmbarkationDate(
				formik.values.scheduled_embarkation_date
			);
			setControlScheduledDisembarkationDate(
				formik.values.scheduled_disembarkation_date
			);
			applyReloadWorkerBoardingData(); // VERIFICAR SE É MESMO NECESSÁRIO
		} catch (error) {
			handleApiError(error);
		}
		setIsUpdatePopupOpen(false);
	};

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllBoardingStatus = await apiGetAllBoardingStatus();
				setDropdownBoardingStatus(backEndAllBoardingStatus);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de status do embarque.');
			}
		};
		fetchDropdownData();
	}, []);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	const handleInfoUpdateClick = () => {
		setShowInfoUpdate(!showInfoUpdate);
	};

	const getActionType = (): string => {
		let message = 'Atualização da programação do embarque.';

		if (checkScheduleDateWorker && !checkNextBoardings) {
			message = 'Atualização da programação do embarque e dos funcionários.';
		}
		if (checkNextBoardings && !checkScheduleDateWorker) {
			message = 'Atualização da programação do embarque e próximos embarques.';
		}
		if (checkScheduleDateWorker && checkNextBoardings) {
			message =
				'Atualização da programação do embarque, funcionários e próximos embarques.';
		}

		return message;
	};

	return (
		<Paper elevation={5} style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '20px' }}>
				Embarque
			</Typography>
			<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
				<Grid container spacing={3} direction="column">
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="projectNumber"
								name="projectNumber"
								label="WO"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.projectNumber}
								onChange={formik.handleChange}
								error={
									formik.touched.projectNumber &&
									Boolean(formik.errors.projectNumber)
								}
								helperText={
									formik.touched.projectNumber && formik.errors.projectNumber
								}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="boardingNumber"
								name="boardingNumber"
								label="Número de embarque"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.boardingNumber}
								onChange={formik.handleChange}
								error={
									formik.touched.boardingNumber &&
									Boolean(formik.errors.boardingNumber)
								}
								helperText={
									formik.touched.boardingNumber && formik.errors.boardingNumber
								}
								// disabled
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="clientName"
								name="clientName"
								label="Cliente"
								value={formik.values.clientName}
								onChange={formik.handleChange}
								error={
									formik.touched.clientName && Boolean(formik.errors.clientName)
								}
								helperText={
									formik.touched.clientName && formik.errors.clientName
								}
								disabled
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								id="platformName"
								name="platformName"
								label="Sonda"
								value={formik.values.platformName}
								onChange={formik.handleChange}
								error={
									formik.touched.platformName &&
									Boolean(formik.errors.platformName)
								}
								helperText={
									formik.touched.platformName && formik.errors.platformName
								}
								disabled
							/>
						</Grid>
					</Grid>

					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="numberOfWorkers"
								name="numberOfWorkers"
								label="Número de Pessoas necessárias"
								type="number"
								InputProps={{ inputProps: { min: 1 } }}
								value={formik.values.numberOfWorkers}
								onChange={formik.handleChange}
								error={
									formik.touched.numberOfWorkers &&
									Boolean(formik.errors.numberOfWorkers)
								}
								helperText={
									formik.touched.numberOfWorkers &&
									formik.errors.numberOfWorkers
								}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel id="boarding-status-id-label">
									Status do embarque
								</InputLabel>
								<Select
									labelId="boarding-status-id-label"
									id="boardingStatus"
									name="boardingStatus"
									value={formik.values.boardingStatus}
									onChange={formik.handleChange}
									error={
										formik.touched.boardingStatus &&
										Boolean(formik.errors.boardingStatus)
									}
									label="Status do embarque" // Use a propriedade label aqui
									// style={{ width: '25%' }}
									style={{ width: '100%', maxWidth: '235px' }}
								>
									{dropdownBoardingStatus.map(boardingStatus => (
										<MenuItem key={boardingStatus.id} value={boardingStatus.id}>
											{boardingStatus.description}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel
								control={
									<Checkbox
										checked={considerDisembarkDay ?? false}
										onChange={e => setConsiderDisembarkDay(e.target.checked)}
										name="considerDisembarkDay"
										color="primary"
									/>
								}
								label="Contabilizar desembarque (projeção e performance)"
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="planned_embarkation_date"
								name="planned_embarkation_date"
								label="Data de embarque (PLAN)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.planned_embarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.planned_embarkation_date &&
									Boolean(formik.errors.planned_embarkation_date)
								}
								helperText={
									formik.touched.planned_embarkation_date &&
									formik.errors.planned_embarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="planned_disembarkation_date"
								name="planned_disembarkation_date"
								label="Data de desembarque (PLAN)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.planned_disembarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.planned_disembarkation_date &&
									Boolean(formik.errors.planned_disembarkation_date)
								}
								helperText={
									formik.touched.planned_disembarkation_date &&
									formik.errors.planned_disembarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="scheduled_embarkation_date"
								name="scheduled_embarkation_date"
								label="Data de embarque (PROG)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.scheduled_embarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.scheduled_embarkation_date &&
									Boolean(formik.errors.scheduled_embarkation_date)
								}
								helperText={
									formik.touched.scheduled_embarkation_date &&
									formik.errors.scheduled_embarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="scheduled_disembarkation_date"
								name="scheduled_disembarkation_date"
								label="Data de desembarque (PROG)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.scheduled_disembarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.scheduled_disembarkation_date &&
									Boolean(formik.errors.scheduled_disembarkation_date)
								}
								helperText={
									formik.touched.scheduled_disembarkation_date &&
									formik.errors.scheduled_disembarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								id="actual_embarkation_date"
								name="actual_embarkation_date"
								label="Data de embarque (REAL)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.actual_embarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.actual_embarkation_date &&
									Boolean(formik.errors.actual_embarkation_date)
								}
								helperText={
									formik.touched.actual_embarkation_date &&
									formik.errors.actual_embarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								id="actual_disembarkation_date"
								name="actual_disembarkation_date"
								label="Data de desembarque (REAL)"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={formik.values.actual_disembarkation_date}
								onChange={formik.handleChange}
								error={
									formik.touched.actual_disembarkation_date &&
									Boolean(formik.errors.actual_disembarkation_date)
								}
								helperText={
									formik.touched.actual_disembarkation_date &&
									formik.errors.actual_disembarkation_date
								}
								style={{ width: '100%', maxWidth: '235px' }}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="observation"
							name="observation"
							label="Observação"
							multiline
							rows={4} // Ajuste conforme necessário para alterar a altura inicial do campo
							value={formik.values.observation}
							onChange={formik.handleChange}
							error={
								formik.touched.observation && Boolean(formik.errors.observation)
							}
							helperText={
								formik.touched.observation && formik.errors.observation
							}
							inputProps={{ maxLength: 1000 }} // Limita o campo a 1000 caracteres
							style={{ width: '100%', maxWidth: '1000px' }}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="projectScopeSow"
							name="projectScopeSow"
							label="Escopo"
							value={formik.values.projectScopeSow}
							onChange={formik.handleChange}
							error={
								formik.touched.projectScopeSow &&
								Boolean(formik.errors.projectScopeSow)
							}
							helperText={
								formik.touched.projectScopeSow && formik.errors.projectScopeSow
							}
							disabled
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						{' '}
						<Typography variant="h6" component="h3">
							Atualizar dados
							<Tooltip title="Informação" arrow>
								<IconButton onClick={handleInfoUpdateClick}>
									<InfoOutlined />
								</IconButton>
							</Tooltip>
						</Typography>
						{showInfoUpdate && (
							<Typography variant="body1" style={{ marginBottom: '20px' }}>
								<div style={{ marginTop: '20px' }}>
									<strong>Data de programação dos funcionários</strong>:
									atualiza as datas dos funcionários programados copiando as
									datas do embarque.
								</div>
								<div style={{ marginTop: '20px' }}>
									<strong>Próximos embarques</strong>: atualiza os dados do
									embarque e também para os próximos embarques. A seguir é
									possível definir quais dados se deseja atualizar para os
									próximos embarques.
								</div>
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<label style={styleCheckBox}>
							<Checkbox
								checked={checkScheduleDateWorker}
								onChange={e => setCheckScheduleDateWorker(e.target.checked)}
								name="checkScheduleDateWorker"
								color="primary"
								sx={{ p: 0, m: 0 }}
							/>
							<span style={{ marginLeft: '8px', fontSize: '16px' }}>
								data de programação dos funcionários
							</span>
						</label>

						<label style={styleCheckBox}>
							<Checkbox
								checked={checkNextBoardings}
								onChange={e => setCheckNextBoardings(e.target.checked)}
								name="checkNextBoardings"
								color="primary"
								sx={{ p: 0, m: 0 }}
							/>
							<span style={{ marginLeft: '8px', fontSize: '16px' }}>
								próximos embarques
							</span>
						</label>

						{checkNextBoardings && (
							<>
								<label style={{ ...styleCheckBox, marginLeft: 30 }}>
									<Checkbox
										checked={checkNBnumberOfWorkers}
										onChange={e => setCheckNBnumberOfWorkers(e.target.checked)}
										name="checkNBnumberOfWorkers"
										color="primary"
										sx={{ p: 0, m: 0 }}
									/>
									<span style={{ marginLeft: '8px', fontSize: '16px' }}>
										numero de pessoas
									</span>
								</label>

								<label style={{ ...styleCheckBox, marginLeft: 30 }}>
									<Checkbox
										checked={checkNBboardingStatus}
										onChange={e => setCheckNBboardingStatus(e.target.checked)}
										name="checkNBboardingStatus"
										color="primary"
										sx={{ p: 0, m: 0 }}
									/>
									<span style={{ marginLeft: '8px', fontSize: '16px' }}>
										status do embarque
									</span>
								</label>

								<label style={{ ...styleCheckBox, marginLeft: 30 }}>
									<Checkbox
										checked={checkNBconsiderDisembarkDay}
										onChange={e =>
											setCheckNBconsiderDisembarkDay(e.target.checked)
										}
										name="checkNBconsiderDisembarkDay"
										color="primary"
										sx={{ p: 0, m: 0 }}
									/>
									<span style={{ marginLeft: '8px', fontSize: '16px' }}>
										contabilizar desembarque
									</span>
								</label>

								<label style={{ ...styleCheckBox, marginLeft: 30 }}>
									<Checkbox
										checked={checkNBscheduledEmbarkationDate}
										onChange={e =>
											setCheckNBscheduledEmbarkationDate(e.target.checked)
										}
										name="checkNBscheduledEmbarkationDate"
										color="primary"
										sx={{ p: 0, m: 0 }}
									/>
									<span style={{ marginLeft: '8px', fontSize: '16px' }}>
										data de programação do embarque
									</span>
								</label>

								<label style={{ ...styleCheckBox, marginLeft: 30 }}>
									<Checkbox
										checked={checkNBscheduledWorkerDate}
										onChange={e =>
											setCheckNBscheduledWorkerDate(e.target.checked)
										}
										name="checkNBscheduledWorkerDate"
										color="primary"
										sx={{ p: 0, m: 0 }}
									/>
									<span style={{ marginLeft: '8px', fontSize: '16px' }}>
										data de programação dos funcionários
									</span>
								</label>
							</>
						)}
					</Grid>

					<Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							disabled={isLoading}
							style={{ marginRight: 20 }}
						>
							Atualizar
						</Button>
						{isLoading && <MoonLoader size={30} />}
					</Grid>
				</Grid>
			</form>
			<ObservationPopup
				open={isUpdatePopupOpen}
				onClose={() => setIsUpdatePopupOpen(false)}
				actionType={getActionType()}
				onSave={handleSaveUpdatePopup}
			/>
		</Paper>
	);
};

export default BoardingFormEditPlanning;
