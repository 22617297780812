import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiDeleteSkill,
	apiUpdateProjecScope,
	apiUpdateSaleOperationRole,
	apiUpdateSkill,
	IProjectScopeBackEnd,
	IProjectScopeInput,
	ISaleOperationRoleBackEnd,
	ISaleOperationRoleInput,
	ISkill,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário

interface UpdateSaleOperationRoleProps {
	skill: ISkill[];
	updatedDataToggle: boolean;
}

function UpdateSkill({
	skill,
	updatedDataToggle,
}: UpdateSaleOperationRoleProps) {
	const [updatedSaleOperationRoles, setUpdatedSaleOperationRoles] = useState<
		ISaleOperationRoleBackEnd[]
	>([]);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	useEffect(() => {
		// Ordena os skilles uma vez quando o componente é montado
		const sortedSaleOperationRoles = [...skill].sort((a, b) =>
			a.description.localeCompare(b.description)
		);
		setUpdatedSaleOperationRoles(sortedSaleOperationRoles);
	}, [skill, updatedDataToggle]);

	const handleUpdateSaleOperationRole = async (
		skill: ISaleOperationRoleBackEnd
	) => {
		if (skill.description === '') {
			toast.error('O campo não pode ser vazio');
			return;
		}

		const updatedSaleOperationRole = {
			description: skill.description,
		};

		try {
			await apiUpdateSkill(skill.id, updatedSaleOperationRole);
			toast.success('Habilidade atualizada com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o habilidade');
		}
	};

	const handleDelete = async (skillId: number) => {
		try {
			await apiDeleteSkill(skillId);
			const updatedSkillsNew = updatedSaleOperationRoles.filter(
				skill => skill.id !== skillId
			);
			setUpdatedSaleOperationRoles(updatedSkillsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: habilidade possui uma relação com nível ou trabalhador. Delete-os antes.'
			);
		}
	};

	const handleNameChange = (id: number, newSaleOperationRole: string) => {
		setUpdatedSaleOperationRoles(prevSaleOperationRoles =>
			prevSaleOperationRoles.map(skill =>
				skill.id === id
					? { ...skill, description: newSaleOperationRole }
					: skill
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Habilidades
				</Typography>
				{updatedSaleOperationRoles.map(skill => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={skill.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Habilidade"
								value={skill.description}
								onChange={e => handleNameChange(skill.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateSaleOperationRole(skill)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setSelectedId(skill.id);
									setIsDeleteDialogOpen(true);
								}}
								sx={{
									backgroundColor: 'red',
									'&:hover': { backgroundColor: 'darkred' },
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdateSkill;
