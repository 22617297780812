import React from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import ProgressBarComponent from './PerformanceOverview/SummaryBar/ProgressBarComponent';

import { IDataSummary, IPerformanceOverview } from '../services/apiService';
import SemiCircularDonut from './PerformanceOverview/SummaryBar/SemiCircularDonut';
import DonutChart from './PerformanceOverview/SummaryBar/DonutChart';

interface DataSummaryProps {
	dataSummary: IDataSummary | undefined;
	startDate: string;
	endDate: string;
}

const SummaryBar: React.FC<DataSummaryProps> = ({
	dataSummary,
	startDate,
	endDate,
}) => {
	const planned = dataSummary?.planned ?? 0;
	const scheduled = dataSummary?.scheduled ?? 0;
	const actual = dataSummary?.actual_until_today ?? 0;
	const actual_plus_scheduled = dataSummary?.actual_plus_scheduled ?? 0;
	const actual_plus_scheduled_by_scheduled =
		dataSummary?.actual_plus_scheduled_by_scheduled ?? 0;
	const actualSchedulePercentage =
		dataSummary?.actual_scheduled_percentage ?? 0;

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			style={{ marginTop: 0, marginBottom: 8, padding: '0 16px' }}
		>
			{/* Retângulos - expandem para ocupar o maior espaço possível */}
			<Grid item xs>
				<Paper
					style={{
						padding: '15px',
						textAlign: 'center',
						backgroundColor: '#007EB8',
						color: 'white',
					}}
				>
					<Typography variant="h4">{planned}</Typography>
					<Typography>Planejado</Typography>
				</Paper>
			</Grid>
			<Grid item xs>
				<Paper
					style={{
						padding: '15px',
						textAlign: 'center',
						backgroundColor: '#001D55',
						color: 'white',
					}}
				>
					<Typography variant="h4">{scheduled}</Typography>
					<Typography>Programado</Typography>
				</Paper>
			</Grid>
			<Grid item xs>
				<Paper
					style={{
						padding: '15px',
						textAlign: 'center',
						backgroundColor: '#000000',
						color: 'white',
					}}
				>
					<Typography variant="h4">{actual_plus_scheduled}</Typography>
					<Typography style={{ whiteSpace: 'nowrap' }}>
						Real + Programado
					</Typography>
				</Paper>
			</Grid>
			<Grid item xs>
				<Paper
					style={{
						padding: '15px',
						textAlign: 'center',
						backgroundColor: '#CCAA14',
						color: 'black',
					}}
				>
					<Typography variant="h4">{actual}</Typography>
					<Typography>Realizado</Typography>
				</Paper>
			</Grid>
			{/* Velocímetro - mantém o tamanho fixo */}
			<Grid item xs="auto">
				<Box>
					<Paper elevation={0}>
						<Box>
							<SemiCircularDonut
								percentage={actual_plus_scheduled_by_scheduled}
							/>
						</Box>
					</Paper>
				</Box>
			</Grid>
		</Grid>
	);
};

export default SummaryBar;
