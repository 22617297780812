import React, { useState, useEffect } from 'react';
import {
	Modal,
	Box,
	Typography,
	IconButton,
	TextField,
	Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ObservationPopupProps {
	open: boolean;
	onClose: () => void;
	actionType: string;
	onSave: (actionType: string, observation: string) => void;
	initialObservation?: string;
	notEmpty?: boolean;
}

const ObservationPopup: React.FC<ObservationPopupProps> = ({
	open,
	onClose,
	actionType,
	onSave,
	initialObservation = '',
	notEmpty = true,
}) => {
	const [observation, setObservation] = useState(initialObservation);

	useEffect(() => {
		if (open) {
			// Reseta o campo caso necessário quando o pop-up é aberto
			setObservation(initialObservation);
		}
	}, [open, initialObservation]);

	const handleSave = () => {
		// Caso notEmpty seja true e o campo esteja vazio, não prossegue.
		if (notEmpty && observation.trim() === '') return;
		// Concatena o prefixText ao texto digitado pelo usuário
		// onSave(`${prefixText}\nObservação: ${observation}`);
		onSave(actionType, observation);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="observation-popup-title"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 600,
					maxHeight: '80vh',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					overflowY: 'auto',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						mb: 2,
					}}
				>
					<Typography id="observation-popup-title" variant="h6">
						{actionType}
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<TextField
					label="Observação"
					multiline
					rows={4}
					fullWidth
					variant="outlined"
					value={observation}
					onChange={e => setObservation(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<Button
					variant="contained"
					onClick={handleSave}
					fullWidth
					disabled={notEmpty && observation.trim() === ''}
				>
					Salvar
				</Button>
			</Box>
		</Modal>
	);
};

export default ObservationPopup;
