import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiDeleteSow,
	apiUpdateProjecScope,
	IProjectScopeBackEnd,
	IProjectScopeInput,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário

interface UpdateSowProps {
	sows: IProjectScopeBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateSow({ sows, updatedDataToggle }: UpdateSowProps) {
	const [updatedSows, setUpdatedSows] = useState<IProjectScopeBackEnd[]>([]);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	useEffect(() => {
		// Ordena os sowes uma vez quando o componente é montado
		const sortedSows = [...sows].sort((a, b) => a.sow.localeCompare(b.sow));
		setUpdatedSows(sortedSows);
	}, [sows, updatedDataToggle]);

	const handleUpdateSow = async (sow: IProjectScopeBackEnd) => {
		if (sow.sow === '') {
			toast.error('Sow não pode ser vazio');
			return;
		}

		const updatedSow = {
			sow: sow.sow,
		};

		try {
			await apiUpdateProjecScope(sow.id, updatedSow);
			toast.success('Sow atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o sow');
		}
	};

	const handleDelete = async (sowId: number) => {
		try {
			await apiDeleteSow(sowId);
			const updatedSowsNew = updatedSows.filter(sow => sow.id !== sowId);
			setUpdatedSows(updatedSowsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o SOW possui um projeto ou um SOW Detais ligado a ele. Altere-o antes.'
			);
		}
	};
	const handleNameChange = (id: number, newSow: string) => {
		setUpdatedSows(prevSows =>
			prevSows.map(sow => (sow.id === id ? { ...sow, sow: newSow } : sow))
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Sows
				</Typography>
				{updatedSows.map(sow => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={sow.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Sow"
								value={sow.sow}
								onChange={e => handleNameChange(sow.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateSow(sow)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setSelectedId(sow.id);
									setIsDeleteDialogOpen(true);
								}}
								sx={{
									backgroundColor: 'red',
									'&:hover': { backgroundColor: 'darkred' },
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdateSow;
