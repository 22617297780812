import React, { useEffect, useState } from 'react';
import {
	IProjectNumberBackEnd,
	IUserBackEnd,
	apiGetAllProjectNumber,
	apiGetAllUsers,
	IBoardingBackend,
	apiGetAllBoarding,
	IClientBackEnd,
	apiGetAllBoardingLast20,
	apiGetAllClient,
	apiGetAllPlatforms,
	IPlatformOnlyBackEnd,
	apiGetBoardingsByFilters,
	IDrakePlatform,
	apiGetAllDrakePlatform,
	apiGetDrakeWorkerBoardingsBasic,
	IDrakeWorkerBoardingBasicBackend,
	apiGetAllDrakeProjectNumber,
	IDrakeProjectNumber,
	apiUpdateDrakeBoardingId,
} from '../../services/apiService';
import {
	Button,
	Card,
	CardContent,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';
import { getMonthBoundaryDate } from '../../utils/getMonthBoundaryDate';
import { platform } from 'os';
import { green } from '@mui/material/colors';
import { existInVisibilityRoleInLocalStorage } from '../../utils/existInVisibilityRoleInLocalStorage';

function formatDateToBrazilian(dateString: string | null): string {
	if (!dateString) {
		return '';
	}
	const [year, month, day] = dateString.split('-');
	return `${day}-${month}-${year}`;
}

interface IDrakeWorkerBoardingAssignmentProps {
	selectedCard: number | null;
	setSelectedCard: (selectedCard: number | null) => void;
	selectedCardBoarding: number | null;
	setSelectedCardBoarding: (selectedCard: number | null) => void;
	selectedWo: number | null;
	setSelectedWo: (selectedWo: number | null) => void;
	selectedWoBoarding: number | null;
	setSelectedWoBoarding: (selectedWo: number | null) => void;
	clickAssign: boolean;
	setClickAssign: (clickAssign: boolean) => void;
}

function DrakeWorkerBoardingAssignment({
	selectedCard,
	setSelectedCard,
	selectedCardBoarding,
	setSelectedCardBoarding,
	selectedWo,
	setSelectedWo,
	selectedWoBoarding,
	setSelectedWoBoarding,
	clickAssign,
	setClickAssign,
}: IDrakeWorkerBoardingAssignmentProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [drakeWorkerBoardings, setDrakeWorkerBoardings] = useState<
		IDrakeWorkerBoardingBasicBackend[]
	>([]);
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IDrakeProjectNumber[]
	>([]);
	const [dropdownPlatform, setDropdownPlatform] = useState<IDrakePlatform[]>(
		[]
	);
	const [selectedPlatform, setSelectedPlatform] = useState<string | ''>('');
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [startDate, setStartDate] = useState<string>(
		getMonthBoundaryDate('first')
	);
	const [endDate, setEndDate] = useState<string>(getMonthBoundaryDate('last'));
	const [hideBoardingAssignmentButton, setHideBoardingAssignmentButton] =
		useState(false);

	useEffect(() => {
		setHideBoardingAssignmentButton(
			existInVisibilityRoleInLocalStorage(
				'hide boarding assignment button (boarding assignment)'
			)
		);

		const fetchDropdownData = async () => {
			try {
				const backEndAllProjectNumber = await apiGetAllDrakeProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}

			try {
				const backEndAllPlatform = await apiGetAllDrakePlatform();
				setDropdownPlatform(backEndAllPlatform);
			} catch (error) {
				toast.error('ERROR: falha ao carregar plataformas do Drake.');
			}

			try {
				const drakeWorkerBoardings = await apiGetDrakeWorkerBoardingsBasic(
					startDate,
					endDate,
					'',
					'embarque',
					''
				);
				setDrakeWorkerBoardings(drakeWorkerBoardings);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados de embarque.');
					} else {
						// toast.warning('WARNING: nenhum embarque encontrado.');
						setDrakeWorkerBoardings([]);
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		fetchDropdownData();
	}, []);

	const handleFilterSubmit = () => {
		const fetchBoardings = async () => {
			try {
				const drakeWorkerBoardings = await apiGetDrakeWorkerBoardingsBasic(
					startDate,
					endDate,
					selectedPlatform,
					'embarque',
					selectedProjectNumber
				);
				setDrakeWorkerBoardings(drakeWorkerBoardings);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados de embarque.');
					} else {
						// toast.warning('WARNING: nenhum embarque encontrado.');
						setDrakeWorkerBoardings([]);
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		};
		setIsLoading(true);
		setSelectedWo(null);
		setSelectedWoBoarding(null);
		setSelectedCard(null);
		setSelectedCardBoarding(null);
		fetchBoardings();
	};

	useEffect(() => {
		setSelectedWo(null);
		setSelectedWoBoarding(null);
		setSelectedCard(null);
		setSelectedCardBoarding(null);
	}, [clickAssign]);

	const handleAssignment = () => {
		console.log('handleAssignment');
		const fetchData = async () => {
			if (selectedWo !== selectedWoBoarding) {
				toast.error('ERROR: WOs diferentes não podem ser associadas.');
			} else {
				try {
					await apiUpdateDrakeBoardingId(selectedCard, selectedCardBoarding);
					setSelectedCard(null);
					setSelectedCardBoarding(null);
					setSelectedWo(null);
					setSelectedWoBoarding(null);
					handleFilterSubmit();
					setClickAssign(!clickAssign);
					toast.success('SUCCESS: embarque associado.');
				} catch (error) {
					toast.error('ERROR: falha ao associar embarque.');
				}
			}
		};

		if (selectedCard === null || selectedCardBoarding === null) {
			toast.warning('WARNING: é preciso selecionar os dois cards.');
		} else {
			fetchData();
		}
	};

	const handleClear = () => {
		setSelectedProjectNumber('');
		setSelectedPlatform('');
		setStartDate('');
		setEndDate('');
	};

	const handleCardClick = (id: number, wo: number) => {
		// Se clicou no mesmo card selecionado, "des-seleciona":
		if (selectedCard === id) {
			setSelectedCard(null);
			setSelectedWo(null);
		} else {
			// Caso contrário, seleciona este card/wo
			setSelectedCard(id);
			setSelectedWo(wo);
		}
	};

	return (
		<>
			<Grid item xs={12}>
				<Paper elevation={5} style={{ padding: 20 }}>
					<Typography
						variant="h5"
						component="h2"
						style={{ marginBottom: '20px' }}
					>
						Embarques Drake
					</Typography>
					<Grid container spacing={3} style={{ marginBottom: '40px' }}>
						<Grid item xs={12} sm={2.7}>
							<TextField
								label="Data início"
								type="date"
								value={startDate}
								onChange={e => setStartDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={2.7}>
							<TextField
								label="Data fim"
								type="date"
								value={endDate}
								onChange={e => setEndDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<FormControl fullWidth>
								<InputLabel id="projectNumber-id-label">WO</InputLabel>
								<Select
									labelId="projectNumber-id-label"
									id="projectNumberId"
									name="projectNumberId"
									value={selectedProjectNumber}
									onChange={e => setSelectedProjectNumber(+e.target.value)}
									label="WO"
								>
									{dropdownProjectNumber
										.sort((a, b) => a.wo_code - b.wo_code)
										.map(projectNumber => (
											<MenuItem
												key={projectNumber.wo_code}
												value={projectNumber.wo_code}
											>
												{projectNumber.wo_code}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={7}>
							<FormControl fullWidth>
								<InputLabel id="platform-id-label">Plataforma</InputLabel>
								<Select
									labelId="platform-id-label"
									id="platformId"
									name="platformId"
									value={selectedPlatform}
									onChange={e => setSelectedPlatform(e.target.value)}
									label="Plataforma"
								>
									{dropdownPlatform
										.sort((a, b) => a.platform.localeCompare(b.platform))
										.map(platform => (
											<MenuItem
												key={platform.platform}
												value={platform.platform}
											>
												{platform.platform}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								{' '}
								<Grid item xs={12} sm={2}>
									<Button
										variant="contained"
										onClick={handleFilterSubmit}
										style={{ height: '56px', marginRight: '8px' }}
										fullWidth
									>
										Filtrar
									</Button>
								</Grid>
								<Grid item xs={12} sm={2}>
									<Button
										variant="contained"
										onClick={handleClear}
										style={{
											height: '56px',
											background: '#e5e5e5',
											color: 'black',
										}}
										fullWidth
									>
										Limpar
									</Button>
								</Grid>
								{!hideBoardingAssignmentButton && (
									<Grid item xs={12} sm={2}>
										<Button
											variant="contained"
											onClick={handleAssignment}
											style={{
												height: '56px',
												background: green[500],
												color: 'white',
											}}
											fullWidth
										>
											<strong>Associar</strong>
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					{isLoading ? (
						<div>
							<MoonLoader color="#36d7b7" />
						</div>
					) : (
						<Grid container spacing={3} direction="column">
							{' '}
							{/* Aumentar a distância entre os cards */}
							{drakeWorkerBoardings
								.sort(
									(a, b) =>
										new Date(a.actual_start_date).getTime() -
										new Date(b.actual_start_date).getTime()
								)
								.sort((a, b) => a.wo_code - b.wo_code)
								.map(row => (
									<Grid item xs={12} key={row.id}>
										<Card
											onClick={() => handleCardClick(row.id, row.wo_code)}
											style={{
												cursor: 'pointer',
												border:
													selectedCard === row.id
														? '2px solid blue'
														: '1px solid grey',
												backgroundColor:
													selectedCard === row.id ? '#f0f8ff' : '#f5f5f5', // cinza leve
											}}
										>
											<CardContent>
												<Grid container spacing={2}>
													{' '}
													{/* Espaçamento uniforme entre as colunas */}
													<Grid item xs={3}>
														{' '}
														{/* Primeira coluna */}
														<Typography>
															WO: <strong>{row.wo_code}</strong>{' '}
															{/* Número do projeto em negrito */}
														</Typography>
														<Typography variant="body2">
															{row.worker.worker_name}
														</Typography>
													</Grid>
													<Grid item xs={5}>
														{' '}
														{/* Aumentar o espaço horizontal da segunda coluna */}
														<Typography variant="body2">
															Embarque:{' '}
															{formatDateToBrazilian(row.actual_start_date)}
														</Typography>
														<Typography variant="body2">
															Desembarque:{' '}
															{formatDateToBrazilian(row.actual_end_date)}
														</Typography>
														<Typography
															variant="body2"
															style={{ marginTop: '10px' }}
														>
															UOP: <strong>{row.event_uop}</strong>
														</Typography>
													</Grid>
													<Grid item xs={4}>
														{' '}
														{/* Terceira coluna */}
														<Typography variant="body2">
															Número embarque: {row.boarding?.boarding_number}
														</Typography>
														<Typography variant="body2">
															WO embarque:{' '}
															{row.boarding?.project?.project_number}
														</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								))}
						</Grid>
					)}
				</Paper>
			</Grid>
		</>
	);
}

export default DrakeWorkerBoardingAssignment;
