import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

interface SemiCircularDonutProps {
	percentage: number;
}

const SemiCircularDonut: React.FC<SemiCircularDonutProps> = ({
	percentage,
}) => {
	const data = {
		datasets: [
			{
				data: [percentage, 150 - percentage],
				backgroundColor: ['#CCAA14', '#E7E9ED'],
				borderWidth: 0,
			},
		],
	};

	const options = {
		circumference: 180,
		rotation: -90,
		cutout: '65%',
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
		layout: {
			padding: {
				top: 0,
				bottom: 0,
			},
		},
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					position: 'relative',
					height: '90px',
					width: '162px',
					overflow: 'hidden',
				}}
			>
				<Doughnut data={data} options={options} />
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, 70%)',
						color: 'black',
						fontSize: '22px',
					}}
				>
					{percentage}%
				</div>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(220%, -95%) rotate(12deg)',
						color: '#001D55',
						fontSize: '32px',
					}}
				>
					/
				</div>
			</div>
			{/* Título abaixo do velocímetro */}
			<Typography
				variant="subtitle1"
				style={{
					marginTop: 8,
					fontFamily: "'Montserrat', sans-serif",
					fontWeight: 900,
				}}
			>
				(Real + Prog) / Prog
			</Typography>
		</div>
	);
};

export default SemiCircularDonut;
