export const formatDateTimeCreatedAt = (s: string | number | Date) =>
	new Date(s).toLocaleString('pt-BR', {
		dateStyle: 'short',
		timeStyle: 'short',
	});

export const formatDateDMY = (dateStr?: string | null) => {
	if (!dateStr) return '';
	const [year, month, day] = dateStr.split('-');
	return `${day}/${month}/${year}`;
};
