import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Filters from '../../components/WorkerHistogram/Filters/Filters';
import {
	IWorkerHistogram,
	apiGetWorkerHistogram,
} from '../../services/apiService';
import { getMonthBoundaryDate } from '../../utils/getMonthBoundaryDate';
import axios from 'axios';
import { toast } from 'react-toastify';
import WorkerHistogramTable from '../../components/WorkerHistogram/WorkerHistogramTable/WorkerHistogramTable';
import { MoonLoader } from 'react-spinners';
import { Fullscreen, FullscreenExit, Download } from '@mui/icons-material';
import { downloadHistogramExcel } from './utils';

function WorkerHistogramPage() {
	const [workerHistogram, setWorkerHistogram] = useState<
		IWorkerHistogram | undefined
	>(undefined);
	const [startDate, setStartDate] = useState<string>(
		getMonthBoundaryDate('first')
	);
	const [endDate, setEndDate] = useState<string>(getMonthBoundaryDate('last'));
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFullScreen, setIsFullScreen] = useState(false); // Novo estado para tela cheia
	const [filterScheduled, setFilterScheduled] = useState(true);
	const [filterScheduledToday, setFilterScheduledToday] = useState(true);
	const [filterNonActiveWorkers, setFilterNonActiveWorkers] = useState(false);

	useEffect(() => {
		const fetchWorkerHistogram = async () => {
			try {
				const workerHistogram = await apiGetWorkerHistogram(
					filterScheduled,
					startDate,
					endDate,
					'',
					'',
					'',
					'',
					'',
					'',
					[],
					[],
					[],
					[],
					!filterNonActiveWorkers,
					filterScheduledToday,
          []
				);
				workerHistogram.workers.sort((a, b) =>
					a.worker_name.trim().localeCompare(b.worker_name.trim())
				);
				setWorkerHistogram(workerHistogram);
			} catch (error) {
				setWorkerHistogram(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 500); // Espera de 1 segundo fixa além do tempo de carregamento dos dados
			}
		};
		fetchWorkerHistogram();
	}, []);

	// Função para baixar os dados do histograma em Excel
	const handleDownloadExcel = () => {
		try {
			downloadHistogramExcel(workerHistogram);
		} catch (error) {
			toast.error('ERROR: Nenhum dado disponível para download.');
		}
	};

	const handleFilterScheduledToday = (value: boolean) => {
		setFilterScheduledToday(value);
	};

	return (
		<Grid style={{ padding: 20 }}>
			<Typography variant="h5" component="h2" style={{ marginBottom: '40px' }}>
				Histograma de pessoas
			</Typography>
			<Filters
				setWorkerHistogram={setWorkerHistogram}
				setIsLoading={setIsLoading}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				filterScheduled={filterScheduled}
				setFilterScheduled={setFilterScheduled}
				filterNonActiveWorkers={filterNonActiveWorkers}
				setFilterNonActiveWorkers={setFilterNonActiveWorkers}
				filterScheduledToday={filterScheduledToday}
				handleFilterScheduledToday={handleFilterScheduledToday}
			/>
			<Box
				style={{
					marginTop: isFullScreen ? 20 : 0,
					...(isFullScreen
						? {
								position: 'fixed',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 9999,
								backgroundColor: 'white',
								padding: 20,
						  }
						: {}),
				}}
			>
				<Box
					display="flex"
					justifyContent="flex-end"
					mb={isFullScreen ? 1 : 0.5} // Margem inferior ajustada
					style={{
						flexDirection: 'row',
						gap: '8px',
						position: isFullScreen ? 'static' : 'static',
						zIndex: 10000,
						marginBottom: isFullScreen ? '12px' : '8px', // Ajuste de margens para ambos os modos
					}}
				>
					<IconButton
						onClick={handleDownloadExcel}
						style={{
							backgroundColor: '#007eb8',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '4px',
						}}
					>
						<Download />
					</IconButton>
					<IconButton
						onClick={() => setIsFullScreen(!isFullScreen)}
						style={{
							backgroundColor: '#007eb8',
							color: 'white',
							width: '30px',
							height: '30px',
							borderRadius: '4px',
						}}
					>
						{isFullScreen ? <FullscreenExit /> : <Fullscreen />}
					</IconButton>
				</Box>
				{isLoading ? (
					<div>
						<MoonLoader color="#36d7b7" />
					</div>
				) : (
					<WorkerHistogramTable
						workerHistogram={workerHistogram}
						startDate={startDate}
						endDate={endDate}
						isFullScreen={isFullScreen} // Passando a prop para o WorkerHistogramTable
					/>
				)}
			</Box>
		</Grid>
	);
}

export default WorkerHistogramPage;
