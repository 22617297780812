import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
} from '@mui/material';
import {
	apiUpdateSkillLevel,
	ISkillLevel,
	ISkill,
	ILevel,
	apiDeleteSkillLevel,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário

interface UpdateSkillLevelProps {
	skillLevels: ISkillLevel[];
	skills: ISkill[];
	levels: ILevel[];
	updatedDataToggle: boolean;
}

function UpdateSkillLevel({
	skillLevels,
	skills,
	levels,
	updatedDataToggle,
}: UpdateSkillLevelProps) {
	const [updatedSkillLevels, setUpdatedSkillLevels] = useState<ISkillLevel[]>(
		[]
	);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	useEffect(() => {
		// Inicializa o estado updatedSkillLevels com os valores de skillLevels
		const initialSkillLevels = skillLevels.map(skillLevel => ({
			...skillLevel,
			skillId: skillLevel.skill.id,
			levelId: skillLevel.level.id,
		}));
		setUpdatedSkillLevels(initialSkillLevels);
	}, [skillLevels, updatedDataToggle]);

	const handleUpdateSkillLevel = async (skillLevel: ISkillLevel) => {
		if (skillLevel.skillId === null || skillLevel.levelId === null) {
			toast.error('Os campos não pode ser nulos');
			return;
		}

		const updatedSkillLevel = {
			skillId: skillLevel.skillId,
			levelId: skillLevel.levelId,
		};

		try {
			await apiUpdateSkillLevel(skillLevel.id, updatedSkillLevel);
			toast.success('Registro atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar registro');
		}
	};

	const handleDelete = async (skillLevelId: number) => {
		try {
			await apiDeleteSkillLevel(skillLevelId);
			const updatedSkillLevelsNew = updatedSkillLevels.filter(
				skillLevel => skillLevel.id !== skillLevelId
			);
			setUpdatedSkillLevels(updatedSkillLevelsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error('Erro: erro ao deletar habilidade / level.');
		}
	};

	const handleSkillChange = (id: number, skillId: number) => {
		const newSkill = skills.find(skill => skill.id === skillId);
		if (!newSkill) return;

		setUpdatedSkillLevels(prevSkillLevels =>
			prevSkillLevels.map(skillLevel =>
				skillLevel.id === id
					? { ...skillLevel, skillId, skill: newSkill }
					: skillLevel
			)
		);
	};

	const handleLevelChange = (id: number, newLevelId: number) => {
		const newLevel = levels.find(level => level.id === newLevelId);
		if (!newLevel) return;

		setUpdatedSkillLevels(prevSkillLevels =>
			prevSkillLevels.map(skillLevel =>
				skillLevel.id === id
					? { ...skillLevel, levelId: newLevelId, level: newLevel }
					: skillLevel
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Habilidades / Níveis
				</Typography>
				{updatedSkillLevels
					.sort((a, b) =>
						a.skill.description.localeCompare(b.skill.description)
					)
					.map(skillLevel => (
						<Grid
							container
							spacing={2}
							alignItems="center"
							key={skillLevel.id}
							style={{ marginBottom: 20 }}
						>
							<Grid item xs={3}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id={`skill-select-label-${skillLevel.id}`}>
										Habilidade
									</InputLabel>
									<Select
										labelId={`skill-select-label-${skillLevel.id}`}
										value={skillLevel.skillId ? String(skillLevel.skillId) : ''}
										onChange={e =>
											handleSkillChange(skillLevel.id, Number(e.target.value))
										}
										label="Habilidade"
									>
										{skills
											.sort((a, b) =>
												a.description.localeCompare(b.description)
											)
											.map(skill => (
												<MenuItem key={skill.id} value={skill.id}>
													{skill.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id={`level-select-label-${skillLevel.id}`}>
										Nível
									</InputLabel>
									<Select
										labelId={`level-select-label-${skillLevel.id}`}
										value={skillLevel.levelId ? String(skillLevel.levelId) : ''}
										onChange={e =>
											handleLevelChange(skillLevel.id, Number(e.target.value))
										}
										label="Nível"
									>
										{levels
											.sort((a, b) =>
												a.description.localeCompare(b.description)
											)
											.map(level => (
												<MenuItem key={level.id} value={level.id}>
													{level.description}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={2}>
								<Button
									color="primary"
									variant="contained"
									onClick={() => handleUpdateSkillLevel(skillLevel)}
								>
									Atualizar
								</Button>
								<Button
									variant="contained"
									onClick={() => {
										setSelectedId(skillLevel.id);
										setIsDeleteDialogOpen(true);
									}}
									sx={{
										backgroundColor: 'red',
										'&:hover': { backgroundColor: 'darkred' },
										color: 'white',
										marginLeft: 2,
									}}
								>
									Deletar
								</Button>
							</Grid>
						</Grid>
					))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdateSkillLevel;
