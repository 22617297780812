import React, { useState, useEffect } from 'react';
import {
	Button,
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	SelectChangeEvent,
} from '@mui/material';
import {
	apiGetAllPlatformsClients,
	apiGetAllProjectClassification,
	apiGetAllProjectNumber,
	apiGetAllProjectScope,
	apiGetAllUsers,
	apiGetWorkerBoardingByCriteria,
	IClientBackEnd,
	IPlatformBackEnd,
	IProjectClassificationBackEnd,
	IProjectNumberBackEnd,
	IProjectScopeBackEnd,
	IUserBackEnd,
	IPerformanceOverview,
	apiGetPerformanceOverview,
	// 1) Import para Categorias de Contrato:
	apiGetAllContractCategory,
	IContractCategoryBackEnd,
} from '../../../services/apiService';
import { removeDuplicatesByIdUtil } from '../../../utils/clientsBackEnd';
import { toast } from 'react-toastify';
import { getMonthBoundaryDate } from '../../../utils/getMonthBoundaryDate';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';

interface FiltersProps {
	setPerformanceOverview: React.Dispatch<
		React.SetStateAction<IPerformanceOverview | undefined>
	>;
	startDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	endDate: string;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: React.FC<FiltersProps> = ({
	setPerformanceOverview,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	isLoading,
	setIsLoading,
}) => {
	const [dropdownClient, setDropdownClient] = useState<IClientBackEnd[]>([]);
	const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
	const [dropdownPlatform, setDropdownPlatform] = useState<IPlatformBackEnd[]>(
		[]
	);
	const [filteredPlatforms, setFilteredPlatforms] = useState<
		IPlatformBackEnd[]
	>([]);
	const [selectedPlatformId, setSelectedPlatformId] = useState<number | ''>('');
	const [dropdownProjectNumber, setDropdownProjectNumber] = useState<
		IProjectNumberBackEnd[]
	>([]);
	const [selectedProjectNumber, setSelectedProjectNumber] = useState<
		number | ''
	>('');
	const [dropdownProjectScope, setDropdownProjectScope] = useState<
		IProjectScopeBackEnd[]
	>([]);
	const [selectedProjectScopeId, setSelectedProjectScopeId] = useState<
		number | ''
	>('');
	const [dropdownProjectClassification, setDropdownProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);
	const [selectedProjectClassification, setSelectedProjectClassification] =
		useState<IProjectClassificationBackEnd[]>([]);

	const [dropdownUser, setDropdownUser] = useState<IUserBackEnd[]>([]);
	const [selectedUserId, setSelectedUserId] = useState<number | ''>('');
	// 2) States para Categoria de Contrato (múltipla)
	const [dropdownContractCategory, setDropdownContractCategory] = useState<
		IContractCategoryBackEnd[]
	>([]);
	const [selectedContractCategoryIds, setSelectedContractCategoryIds] =
		useState<number[]>([]);

	useEffect(() => {
		const fetchDropdownData = async () => {
			try {
				const backEndAllPlatformsClients = await apiGetAllPlatformsClients();
				const clients = backEndAllPlatformsClients.map(obj => obj.client);
				const clientsUnique = removeDuplicatesByIdUtil(clients);
				setDropdownClient(clientsUnique);
				setDropdownPlatform(backEndAllPlatformsClients);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de clientes e plataformas.'
				);
			}
			try {
				const backEndAllProjectNumber = await apiGetAllProjectNumber();
				setDropdownProjectNumber(backEndAllProjectNumber);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados dos números dos projetos.'
				);
			}
			try {
				const backEndAllUser = await apiGetAllUsers();
				setDropdownUser(backEndAllUser);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de coordenadores.');
			}
			try {
				const backEndAllProjectClassification =
					await apiGetAllProjectClassification();
				setDropdownProjectClassification(backEndAllProjectClassification);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de classificação de projeto.'
				);
			}

			try {
				const backEndAllProjectScope = await apiGetAllProjectScope();
				setDropdownProjectScope(backEndAllProjectScope);
			} catch (error) {
				toast.error('ERROR: falha ao carregar os dados de escopo de projeto.');
			}

			// 3) Buscar categorias de contrato
			try {
				const backEndAllContractCategory = await apiGetAllContractCategory();
				setDropdownContractCategory(backEndAllContractCategory);
			} catch (error) {
				toast.error(
					'ERROR: falha ao carregar os dados de categorias de contrato.'
				);
			}
		};

		fetchDropdownData();
	}, []);

	// Filtra as plataformas com base no cliente selecionado
	useEffect(() => {
		if (selectedClientId) {
			const filtered = dropdownPlatform.filter(
				platform => platform.client.id === selectedClientId
			);
			setFilteredPlatforms(filtered);
		} else {
			setFilteredPlatforms([]);
		}
	}, [selectedClientId]);

	// 4) Função para manipular Múltiplas Categorias
	const handleChangeSelectedContractCategoryIds = (
		event: SelectChangeEvent<number[]>
	) => {
		const {
			target: { value },
		} = event;
		const selectedIds =
			typeof value === 'string' ? value.split(',').map(Number) : value;
		setSelectedContractCategoryIds(selectedIds);
	};

	const handleFilterSubmit = async () => {
		const filters = {
			startDate,
			endDate,
			selectedClientId,
			selectedPlatformId,
			selectedProjectNumber,
			selectedProjectScopeId,
			selectedProjectClassificationIds: selectedProjectClassification.map(
				pc => pc.id
			),
			selectedUserId,
			// 5) Caso queira enviar também pro backend
			selectedContractCategoryIds,
		};

		console.log('🚀  filters:', filters);

		const fetchPerformanceOverview = async () => {
			try {
				// Se quiser filtrar por Categorias, adicione também como parâmetro aqui:
				const performanceOverview = await apiGetPerformanceOverview(
					startDate,
					endDate,
					selectedClientId,
					selectedPlatformId,
					selectedProjectNumber,
					selectedProjectScopeId,
					selectedProjectClassification.map(pc => pc.id),
					selectedUserId,
					selectedContractCategoryIds
				);

				setPerformanceOverview(performanceOverview);
				console.log('🚀  performanceOverview:', performanceOverview);
			} catch (error) {
				setPerformanceOverview(undefined);
				if (axios.isAxiosError(error)) {
					if (error.response?.status !== 404) {
						toast.error('ERROR: falha ao carregar dados.');
					} else {
						toast.warning('WARNING: nenhum dado encontrado.');
					}
				} else {
					toast.error('ERROR: falha ao carregar dados.');
				}
			}
		};
		if (startDate === '' || endDate === '') {
			toast.warning('As datas devem estar preenchidas.');
		} else {
			setIsLoading(true);
			await fetchPerformanceOverview();
			setIsLoading(false);
		}
	};

	const handleClear = () => {
		setSelectedPlatformId('');
		setSelectedProjectNumber('');
		setSelectedClientId('');
		setSelectedProjectScopeId('');
		setSelectedProjectClassification([]);
		setSelectedUserId('');
		setStartDate('');
		setEndDate('');
		// 6) limpar Categorias de Contrato
		setSelectedContractCategoryIds([]);
	};

	// Filtro múltiplo para “Tipo” (ProjectClassification)
	const handleChangeSelectedProjectClassification = (
		event: SelectChangeEvent<number[]>
	) => {
		const {
			target: { value },
		} = event;
		const selectedIds =
			typeof value === 'string' ? value.split(',').map(Number) : value;
		const selectedClassifications = dropdownProjectClassification.filter(pc =>
			selectedIds.includes(pc.id)
		);
		setSelectedProjectClassification(selectedClassifications);
	};

	return (
		<Box display="flex" flexWrap="wrap" gap="20px">
			<TextField
				label="Data início"
				type="date"
				value={startDate}
				onChange={e => setStartDate(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Data fim"
				type="date"
				value={endDate}
				onChange={e => setEndDate(e.target.value)}
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<FormControl style={{ minWidth: '150px' }}>
				<InputLabel>Cliente</InputLabel>
				<Select
					label="Cliente"
					value={selectedClientId}
					onChange={e => {
						setSelectedClientId(+e.target.value);
						setSelectedPlatformId('');
					}}
				>
					{dropdownClient
						.sort((a, b) => a.client_name.localeCompare(b.client_name))
						.map(client => (
							<MenuItem key={client.id} value={client.id}>
								{client.client_name}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			<FormControl style={{ minWidth: '150px' }}>
				<InputLabel>Rigs</InputLabel>
				<Select
					label="Rigs"
					value={selectedPlatformId}
					onChange={e => setSelectedPlatformId(+e.target.value)}
				>
					{filteredPlatforms
						.sort((a, b) => a.platform_name.localeCompare(b.platform_name))
						.map(platform => (
							<MenuItem key={platform.id} value={platform.id}>
								{platform.platform_name}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			<FormControl style={{ minWidth: '150px' }}>
				<InputLabel>WO</InputLabel>
				<Select
					label="WO"
					value={selectedProjectNumber}
					onChange={e => setSelectedProjectNumber(+e.target.value)}
				>
					{dropdownProjectNumber
						.sort((a, b) => a.project_number - b.project_number)
						.map(projectNumber => (
							<MenuItem
								key={projectNumber.id}
								value={projectNumber.project_number}
							>
								{projectNumber.project_number}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			<FormControl style={{ minWidth: '150px' }}>
				<InputLabel>Escopo</InputLabel>
				<Select
					label="Escopo"
					value={selectedProjectScopeId}
					onChange={e => setSelectedProjectScopeId(+e.target.value)}
				>
					{dropdownProjectScope
						.sort((a, b) => a.sow.localeCompare(b.sow))
						.map(project_scope => (
							<MenuItem key={project_scope.id} value={project_scope.id}>
								{project_scope.sow}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			<FormControl style={{ minWidth: '150px' }}>
				<InputLabel>Tipo</InputLabel>
				<Select
					label="Tipo"
					multiple
					value={selectedProjectClassification.map(pc => pc.id)}
					onChange={handleChangeSelectedProjectClassification}
					renderValue={selectedIds => {
						const selectedDescriptions = dropdownProjectClassification
							.filter(pc => (selectedIds as number[]).includes(pc.id))
							.map(pc => pc.description);
						return selectedDescriptions.join(', ');
					}}
				>
					{dropdownProjectClassification
						.sort((a, b) => a.description.localeCompare(b.description))
						.map(pc => (
							<MenuItem key={pc.id} value={pc.id}>
								{pc.description}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			{/* FIM do campo de Categoria de Contrato */}

			<Box display="flex" justifyContent="flex-start" gap="20px" width="100%">
				<FormControl style={{ minWidth: '150px' }}>
					<InputLabel>Coordenador</InputLabel>
					<Select
						label="Coordenador"
						value={selectedUserId}
						onChange={e => setSelectedUserId(+e.target.value)}
					>
						{dropdownUser
							.sort((a, b) => a.username.localeCompare(b.username))
							.filter(user => user.coordinator)
							.map(coordinator => (
								<MenuItem key={coordinator.id} value={coordinator.id}>
									{coordinator.username}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				{/* 7) Campo MÚLTIPLO para Categoria de Contrato */}
				<FormControl style={{ minWidth: '220px' }}>
					<InputLabel>Categoria de Contrato</InputLabel>
					<Select
						label="Categoria de Contrato"
						multiple
						value={selectedContractCategoryIds}
						onChange={handleChangeSelectedContractCategoryIds}
						renderValue={selected => {
							const selectedDescriptions = dropdownContractCategory
								.filter(cat => (selected as number[]).includes(cat.id))
								.map(cat => cat.description);
							return selectedDescriptions.join(', ');
						}}
					>
						{dropdownContractCategory
							.sort((a, b) => a.description.localeCompare(b.description))
							.map(cat => (
								<MenuItem key={cat.id} value={cat.id}>
									{cat.description}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<Button
					variant="contained"
					onClick={handleFilterSubmit}
					style={{ height: '56px' }}
					disabled={isLoading}
				>
					Filtrar
				</Button>
				<Button
					variant="contained"
					onClick={handleClear}
					style={{
						background: '#e5e5e5',
						color: 'black',
					}}
					disabled={isLoading}
				>
					Limpar
				</Button>
				{isLoading && <MoonLoader size={30} />}
			</Box>
		</Box>
	);
};

export default Filters;
