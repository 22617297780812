import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import {
	IProjectScopeBackEnd,
	IProjectScopeDetaisSowBackEnd,
	apiDeleteSowDetais,
	apiUpdateProjecScopeDetais,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário

interface UpdateSowDetaisProps {
	sowsDetais: IProjectScopeDetaisSowBackEnd[];
	sows: IProjectScopeBackEnd[];
	updatedDataToggle: boolean;
}

function UpdateSowDetais({
	sowsDetais,
	sows,
	updatedDataToggle,
}: UpdateSowDetaisProps) {
	const [updatedSowsDetais, setUpdatedSowsDetais] = useState<
		IProjectScopeDetaisSowBackEnd[]
	>([]);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	useEffect(() => {
		// Filtra e ordena as plataformas uma vez quando o componente é montado
		const filteredAndSortedSowsDetais = [...sowsDetais]
			.filter(sowDetais => sowDetais.sow_detais !== undefined)
			.sort((a, b) => a.sow_detais.localeCompare(b.sow_detais));
		setUpdatedSowsDetais(filteredAndSortedSowsDetais);
	}, [sowsDetais, updatedDataToggle]);

	const handleUpdateSowDetais = async (
		sowDetais: IProjectScopeDetaisSowBackEnd
	) => {
		if (!sowDetais.sow) {
			toast.error('Sow Detais não pode ser nulo');
			return;
		}

		const updatedSowDetais = {
			sowDetais: sowDetais.sow_detais,
			projectScopeId: sowDetais.sow.id,
		};

		try {
			await apiUpdateProjecScopeDetais(sowDetais.id, updatedSowDetais);
			toast.success('Plataforma atualizada com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar a plataforma');
		}
	};

	const handleDelete = async (sowDetaisId: number) => {
		try {
			await apiDeleteSowDetais(sowDetaisId);
			const updatedSowDetaisNew = updatedSowsDetais.filter(
				sowDetais => sowDetais.id !== sowDetaisId
			);
			setUpdatedSowsDetais(updatedSowDetaisNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o SOW Detais possui um projeto ligado a ele. Altere-o antes.'
			);
		}
	};

	const handleNameChange = (id: number, newName: string) => {
		setUpdatedSowsDetais(prevSowsDetais =>
			prevSowsDetais.map(sowDetais =>
				sowDetais.id === id ? { ...sowDetais, sow_detais: newName } : sowDetais
			)
		);
	};

	const handleSowChange = (id: number, newSowId: number) => {
		const newSow = sows.find(sow => sow.id === newSowId);
		if (!newSow) return;

		setUpdatedSowsDetais(prevSowsDetais =>
			prevSowsDetais.map(sowDetais =>
				sowDetais.id === id ? { ...sowDetais, sow: newSow } : sowDetais
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Sow Detais
				</Typography>
				{updatedSowsDetais.map(sowDetais => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={sowDetais.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label="Sow Detais"
								value={sowDetais.sow_detais}
								onChange={e => handleNameChange(sowDetais.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id={`sow-select-label-${sowDetais.id}`}>
									Sow
								</InputLabel>
								<Select
									labelId={`sow-select-label-${sowDetais.id}`}
									value={sowDetais.sow ? String(sowDetais.sow.id) : ''}
									onChange={e =>
										handleSowChange(sowDetais.id, Number(e.target.value))
									}
									label="Sow"
								>
									{sows.map(sow => (
										<MenuItem key={sow.id} value={sow.id}>
											{sow.sow}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateSowDetais(sowDetais)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setSelectedId(sowDetais.id);
									setIsDeleteDialogOpen(true);
								}}
								sx={{
									backgroundColor: 'red',
									'&:hover': { backgroundColor: 'darkred' },
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdateSowDetais;
