import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

interface ConfirmRowDeleteDialogProps {
	/** Define se o diálogo está aberto */
	open: boolean;
	/** Função a ser chamada se o usuário confirmar a deleção */
	onConfirm: () => void;
	/** Função a ser chamada se o usuário cancelar a deleção */
	onCancel: () => void;
}

const ConfirmRowDeleteDialog: React.FC<ConfirmRowDeleteDialogProps> = ({
	open,
	onConfirm,
	onCancel,
}) => {
	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle>Confirmação de Deleção</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Tem certeza que deseja deletar este embarque?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					Não
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmRowDeleteDialog;
