import { IClientBackEnd, IPlatformBackEnd } from '../services/apiService';

export function removeDuplicatesByIdUtil(
	clients: IClientBackEnd[]
): IClientBackEnd[] {
	const uniqueIds = new Set<number>();
	const uniqueClients: IClientBackEnd[] = [];

	for (const client of clients) {
		if (!uniqueIds.has(client.id)) {
			uniqueIds.add(client.id);
			uniqueClients.push(client);
		}
	}

	return uniqueClients;
}

export function removeDuplicatesByPlatformCode(
	platforms: IPlatformBackEnd[]
): IPlatformBackEnd[] {
	const uniqueCodes = new Set<string>();
	const uniquePlatforms: IPlatformBackEnd[] = [];

	for (const platform of platforms) {
		if (!uniqueCodes.has(platform.platform_code)) {
			uniqueCodes.add(platform.platform_code);
			uniquePlatforms.push(platform);
		}
	}

	return uniquePlatforms;
}
