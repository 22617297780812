import { toast } from 'react-toastify';
import {
	apiCreateImage,
	apiCreateProject,
	apiDeleteAllImages,
	apiDeleteProject,
	apiDeleteProjectByProjectNumber,
	apiGetLastProjectNumber,
	IProjectFormBackend,
} from './apiService';
import axios, { AxiosError } from 'axios';

interface IAddProjectBackendProps {
	formik: any;
	projectForm: IProjectFormBackend;
	setSaleSelectedOperationRoleIdsOptions: (v: number[]) => void;
	setProjectScopeDropdowns: (v: number[]) => void;
	setLastProjectNumber: (n: number) => void;
	setIsSubmitting: (b: boolean) => void;
}

export const addProjectBackend = async ({
	formik,
	projectForm,
	setSaleSelectedOperationRoleIdsOptions,
	setProjectScopeDropdowns,
	setLastProjectNumber,
	setIsSubmitting,
}: IAddProjectBackendProps): Promise<void> => {
	try {
		// Criação do projeto
		const username = localStorage.getItem('username') ?? '';
		const newProject = await apiCreateProject(projectForm, username);

		// Criação das imagens
		try {
			for (const image of projectForm.images) {
				// Garantia de erro se falhar
				await apiCreateImage(projectForm.projectNumber, image);
			}
		} catch (error) {
			// Limpeza de imagens e projeto em caso de erro
			await apiDeleteProjectByProjectNumber(projectForm.projectNumber);
			await apiDeleteAllImages(projectForm.projectNumber);

			toast.error('ERROR: falha ao salvar imagens.');
			throw new Error(
				'Falha ao salvar imagens e limpeza realizada. Detalhes: ' +
					(error as Error).message
			);
		}

		// Executa apenas se tudo acima funcionar sem erro
		toast.success('SUCCESS: projeto criado');
		formik.resetForm();
		setSaleSelectedOperationRoleIdsOptions([]);
		setProjectScopeDropdowns([]);

		// Atualização do número do último projeto
		try {
			const lastProjectNumber = await apiGetLastProjectNumber();
			setLastProjectNumber(lastProjectNumber.project_number + 1);
			formik.setFieldValue(
				'projectNumber',
				lastProjectNumber.project_number + 1
			);
		} catch (error) {
			toast.error('ERROR: falha ao carregar o número do último projeto.');
		}
	} catch (error) {
		// Tratamento de erros gerais
		if (axios.isAxiosError(error)) {
			const axiosError = error as AxiosError;
			if (
				axiosError.response &&
				axiosError.response.data !== null &&
				typeof axiosError.response.data === 'object' &&
				'detail' in axiosError.response.data
			) {
				const errorMessage = axiosError.response.data.detail;
				toast.error('ERROR: ' + (errorMessage as string));
			} else {
				toast.error('ERROR: erro desconhecido (*1)');
			}
		} else {
			toast.error('ERROR: erro desconhecido (*2)');
		}
	} finally {
		setIsSubmitting(false); // Desativa o spinner
	}
};
