import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	IBoardingBackend,
	IBoardingChangeDescriptionBackend,
	IDrakeWorkerBoardingBackEnd,
	IWorkerBoardingBackEnd,
	apiAddBoardingChangeDescription,
	apiAddWorkerBoarding,
	apiGetBoardingById,
	apiGetBoardingChangeDescription,
	apiGetDrakeWorkerBoardingByBoardingId,
	apiGetWorkerBoardingByBoardingId,
} from '../../services/apiService';
import { toast } from 'react-toastify';
import BoardingFormEditPlanning from '../../components/EditPlanning/BoardingFormEditPlanning';
import DrakeWorkerEditPlanning from '../../components/EditPlanning/DrakeWorkerEditPlanning';
import WorkerFormEditPlanning from '../../components/WorkerFormEditPlanning/WorkerFormEditPlanning';
import {
	Button,
	Grid,
	Paper,
	Typography,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { MoonLoader } from 'react-spinners';
import axios from 'axios';
import { green } from '@mui/material/colors';
import ObservationPopup from '../../components/ObservationPopup';
import BoardingChangeDescriptionEditPlanningTable from '../../components/EditPlanning/BoardingChangeDescriptionEditPlanningTable';

function EditPlanningPage() {
	const { id } = useParams();
	const [workerBoardings, setWorkerBoardings] = useState<
		IWorkerBoardingBackEnd[]
	>([]);
	const [drakeWorkerBoardings, setDrakeWorkerBoardings] = useState<
		IDrakeWorkerBoardingBackEnd[]
	>([]);
	const [boardingChangeDescription, setBoardingChangeDescription] = useState<
		IBoardingChangeDescriptionBackend[]
	>([]);
	const [boardings, setBoardings] = useState<IBoardingBackend[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(true);
	const [reloadWorkerBoardingData, setReloadWorkerBoardingData] =
		useState<boolean>(false);

	// Estado para controlar o pop-up de observação para adicionar vaga
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	// Estado para controlar se o popup de observação deve ser usado
	const [isObservationEnabled, setIsObservationEnabled] = useState(false);

	const handleClosePopup = () => {
		setIsPopupOpen(false);
	};

	const applyReloadWorkerBoardingData = useCallback(() => {
		setReloadWorkerBoardingData(prev => !prev);
	}, []);

	const handleRemoveWorkerBoarding = (workerBoardingId: number) => {
		setWorkerBoardings(currentEvents =>
			currentEvents.filter(item => item.id !== workerBoardingId)
		);
	};

	const fetchWorkerBoardingData = async () => {
		try {
			if (id !== undefined) {
				const backEndBoardings = await apiGetBoardingById(+id);
				setBoardings(backEndBoardings);
			}
		} catch (error) {
			console.log('🚀  error:', error);
			toast.error('ERROR: falha ao carregar os dados de embarque.');
		} finally {
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		}

		try {
			if (id !== undefined) {
				const backEndWorkerBoardings = await apiGetWorkerBoardingByBoardingId(
					+id
				);
				setWorkerBoardings([...backEndWorkerBoardings]);
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 404) {
					setWorkerBoardings([]);
				} else {
					toast.error(
						'ERROR: falha ao carregar funcionários embarcados (programado).'
					);
				}
			} else {
				toast.error(
					'ERROR: falha ao carregar funcionários embarcados (programado).'
				);
			}
		} finally {
			setTimeout(() => {
				setIsLoading(false);
			}, 250);
		}

		try {
			if (id !== undefined) {
				try {
					const boardingChangeDescription =
						await apiGetBoardingChangeDescription(+id);
					setBoardingChangeDescription(boardingChangeDescription);
				} catch (error) {
					if (axios.isAxiosError(error)) {
						if (error.response?.status === 404) {
							setBoardingChangeDescription([]);
						} else {
							toast.error('ERROR: falha ao carregar histórico da programação.');
						}
					} else {
						toast.error('ERROR: falha ao carregar histórico da programação.');
					}
				}

				const backEndDrakeWorkerBoardings =
					await apiGetDrakeWorkerBoardingByBoardingId(+id);
				setDrakeWorkerBoardings(backEndDrakeWorkerBoardings);
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response?.status === 404) {
					setDrakeWorkerBoardings([]);
				} else {
					toast.error(
						'ERROR: falha ao carregar funcionários embarcados (realizado) ou dados de registro.'
					);
				}
			} else {
				toast.error(
					'ERROR: falha ao carregar funcionários embarcados (realizado) ou dados de registro.'
				);
			}
		} finally {
			setTimeout(() => {
				setIsLoading2(false);
			}, 100);
		}
	};

	useEffect(() => {
		fetchWorkerBoardingData();
	}, []);

	useEffect(() => {
		fetchWorkerBoardingData();
	}, [reloadWorkerBoardingData]);

	// Função para adicionar a vaga sem observação (caso o checkbox não esteja marcado)
	const handleAddWorkerBoardingWithoutObservation = async () => {
		try {
			const addWorker = {
				boarding_id: boardings[0]?.id,
				worker_id: null,
				scheduled_start_date: boardings[0]?.scheduled_embarkation_date,
				scheduled_end_date: boardings[0]?.scheduled_disembarkation_date,
				observation: '', // Sem observação
			};
			await apiAddWorkerBoarding(addWorker);
			applyReloadWorkerBoardingData();
			toast.success('SUCCESS: vaga adicionada com sucesso.');
		} catch (error) {
			toast.error('ERROR: não foi possível adicionar a vaga no embarque.');
		}
	};

	// Ao clicar em "Adicionar vaga", verifica se deve abrir o popup ou adicionar diretamente
	const handleAddWorkerBoarding = () => {
		if (isObservationEnabled) {
			setIsPopupOpen(true);
		} else {
			handleAddWorkerBoardingWithoutObservation();
		}
	};

	// Função chamada ao salvar a observação via popup (quando o checkbox está marcado)
	const handleSaveAddWorkerBoarding = async (
		actionType: string,
		observation: string
	) => {
		console.log('Action Type recebido:', actionType); // Verifique no console
		try {
			const addWorker = {
				boarding_id: boardings[0]?.id,
				worker_id: null,
				scheduled_start_date: boardings[0]?.scheduled_embarkation_date,
				scheduled_end_date: boardings[0]?.scheduled_disembarkation_date,
				observation, // observação enviada pelo popup
			};
			const username = localStorage.getItem('username') ?? '';
			const addBoardingChangeDescription = {
				boarding_id: boardings[0]?.id,
				action_type: actionType, // campo enviado para o backend
				observation: observation,
				worker_name: null,
				scheduled_embarkation_date_before:
					boardings[0]?.scheduled_embarkation_date,
				scheduled_disembarkation_date_before:
					boardings[0]?.scheduled_disembarkation_date,
				scheduled_embarkation_date_after:
					boardings[0]?.scheduled_embarkation_date,
				scheduled_disembarkation_date_after:
					boardings[0]?.scheduled_disembarkation_date,
				changed_number_boarding: null,
				user: username,
			};

			await apiAddBoardingChangeDescription(addBoardingChangeDescription);
			await apiAddWorkerBoarding(addWorker);

			applyReloadWorkerBoardingData();
			toast.success('SUCCESS: vaga adicionada com sucesso.');
		} catch (error) {
			toast.error('ERROR: não foi possível adicionar a vaga no embarque.');
		}
		setIsPopupOpen(false);
	};

	return (
		<>
			{isLoading || isLoading2 ? (
				<div>
					<MoonLoader color="#36d7b7" />
				</div>
			) : (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<BoardingFormEditPlanning
							boarding={boardings[0]}
							applyReloadWorkerBoardingData={applyReloadWorkerBoardingData}
						/>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={5} style={{ padding: 20 }}>
							<Typography
								variant="h5"
								component="h2"
								style={{ marginBottom: '20px' }}
							>
								Funcionários programados
							</Typography>
							<Grid
								container
								alignItems="center"
								spacing={2}
								style={{ marginBottom: '25px' }}
							>
								<Grid item>
									<Button
										variant="contained"
										onClick={handleAddWorkerBoarding}
										sx={{
											backgroundColor: green[500],
											'&:hover': {
												backgroundColor: green[700],
											},
											color: 'white',
										}}
									>
										<strong>Adicionar vaga</strong>
									</Button>
								</Grid>
								<Grid item>
									<FormControlLabel
										control={
											<Checkbox
												checked={isObservationEnabled}
												onChange={e =>
													setIsObservationEnabled(e.target.checked)
												}
											/>
										}
										label="salvar observação"
									/>
								</Grid>
							</Grid>
							<div>
								{workerBoardings.map(item => (
									<Grid
										item
										key={`${item.id}-${item.scheduled_start_date}-${item.scheduled_end_date}`}
										style={{
											marginBottom: '40px',
											borderTop: '1px solid #b0aeae',
											paddingTop: '40px',
										}}
									>
										<WorkerFormEditPlanning
											workerBoarding={item}
											onRemove={handleRemoveWorkerBoarding}
											applyReloadWorkerBoardingData={
												applyReloadWorkerBoardingData
											}
											enableObservation={isObservationEnabled}
										/>
									</Grid>
								))}
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper elevation={5} style={{ padding: 20 }}>
							<Typography
								variant="h5"
								component="h2"
								style={{ marginBottom: '20px' }}
							>
								Embarcados
							</Typography>
							<div>
								{drakeWorkerBoardings.map(item => (
									<Grid
										item
										key={item.id}
										style={{
											marginBottom: '40px',
											borderTop: '1px solid #b0aeae',
											paddingTop: '40px',
										}}
									>
										<DrakeWorkerEditPlanning drakeWorkerBoarding={item} />
									</Grid>
								))}
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper elevation={5} style={{ padding: 20 }}>
							<Typography
								variant="h5"
								component="h2"
								style={{ marginBottom: '20px' }}
							>
								Histórico de programação
							</Typography>
							<div>
								<BoardingChangeDescriptionEditPlanningTable
									boardingChangeDescription={boardingChangeDescription}
								/>
							</div>
						</Paper>
					</Grid>
				</Grid>
			)}
			<ObservationPopup
				open={isPopupOpen}
				onClose={handleClosePopup}
				actionType="Adição de vaga no embarque."
				onSave={handleSaveAddWorkerBoarding}
			/>
		</>
	);
}

export default EditPlanningPage;
