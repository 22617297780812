import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';


interface ConfirmWorkerDeleteDialogProps {
	/** Define se o diálogo está aberto */
	open: boolean;
	/** Função chamada quando o usuário confirma a deleção */
	onConfirm: () => void;
	/** Função chamada quando o usuário cancela/fecha o diálogo */
	onCancel: () => void;
}

const ConfirmWorkerDeleteDialog: React.FC<ConfirmWorkerDeleteDialogProps> = ({
	open,
	onConfirm,
	onCancel,
}) => {
	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle>Confirmação de Deleção</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Tem certeza que deseja deletar este registro?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary">
					Não
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Sim
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmWorkerDeleteDialog;

