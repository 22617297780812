import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Typography,
	FormControl,
	InputLabel,
	TextField,
} from '@mui/material';
import { green } from '@mui/material/colors';
import {
	apiUpdateProjecScope,
	apiUpdateLevel,
	IProjectScopeBackEnd,
	IProjectScopeInput,
	ILevelInput,
  ILevel,
  apiDeleteLevel,
} from '../../../services/apiService';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from './../ConfirmDeleteDialog'; // ajuste o caminho conforme necessário


interface UpdateLevelProps {
	level: ILevel[];
	updatedDataToggle: boolean;
}

function UpdateLevel({
	level: Level,
	updatedDataToggle,
}: UpdateLevelProps) {
	const [updatedLevels, setUpdatedLevels] = useState<
		ILevel[]
	>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<number | null>(null);




	useEffect(() => {
		// Ordena os leveles uma vez quando o componente é montado
		const sortedLevels = [...Level].sort((a, b) =>
			a.description.localeCompare(b.description)
		);
		setUpdatedLevels(sortedLevels);
	}, [Level, updatedDataToggle]);

	const handleUpdateLevel = async (
		level: ILevel
	) => {
		if (level.description === '') {
			toast.error('O campo não pode ser vazio');
			return;
		}

		const updatedLevel = {
			description: level.description,
		};

		try {
			await apiUpdateLevel(
				level.id,
				updatedLevel
			);
			toast.success('Level atualizado com sucesso');
		} catch (error) {
			toast.error('Erro ao atualizar o level');
		}
	};

  const handleDelete = async (levelId: number) => {
		try {
			await apiDeleteLevel(levelId);
			const updatedLevelsNew = updatedLevels.filter(
				level => level.id !== levelId
			);
			setUpdatedLevels(updatedLevelsNew);
			toast.success('Registro deletado com sucesso!');
		} catch (error) {
			toast.error(
				'Erro: o nível possui uma habilidade ou trabalhador ligado a ele. Altere-os antes.'
			);
		}
	};

	const handleNameChange = (id: number, newLevel: string) => {
		setUpdatedLevels(prevLevels =>
			prevLevels.map(level =>
				level.id === id
					? { ...level, description: newLevel }
					: level
			)
		);
	};

	return (
		<Grid item xs={12}>
			<Paper elevation={5} style={{ padding: 20 }}>
				<Typography
					variant="h6"
					component="h3"
					style={{ marginBottom: '20px' }}
				>
					Níveis
				</Typography>
				{updatedLevels.map(level => (
					<Grid
						container
						spacing={2}
						alignItems="center"
						key={level.id}
						style={{ marginBottom: 20 }}
					>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Nível"
								value={level.description}
								onChange={e => handleNameChange(level.id, e.target.value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleUpdateLevel(level)}
							>
								Atualizar
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setSelectedId(level.id);
									setIsDeleteDialogOpen(true);
								}}
								sx={{
									backgroundColor: 'red',
									'&:hover': { backgroundColor: 'darkred' },
									color: 'white',
									marginLeft: 2,
								}}
							>
								Deletar
							</Button>
						</Grid>
					</Grid>
				))}
			</Paper>
			<ConfirmDeleteDialog
				open={isDeleteDialogOpen}
				onConfirm={() => {
					if (selectedId !== null) {
						handleDelete(selectedId);
					}
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setSelectedId(null);
				}}
			/>
		</Grid>
	);
}

export default UpdateLevel;
